import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)`
    padding: 8px 16px;
    margin: 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    background-color: ${props => props.primary ? props.theme.palette.primary.main : 'transparent'};
    color: ${props => props.primary ? 'white' : props.theme.palette.primary.main};

    &:hover {
        background-color: ${props => props.primary ? props.theme.palette.primary.dark : props.theme.palette.grey[100]};
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    padding: 8px 24px;
`;

export const ConfirmationDialog = ({
    open,
    title,
    message,
    onCancel,
    onConfirm,
    confirmText = "Confirmar",
    cancelText = "Cancelar",
    loading,
    children,
    BtnConfirm = true
}) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
                {children}
            </DialogContent>
            <StyledDialogActions>
                <StyledButton onClick={onCancel}>
                    {cancelText}
                </StyledButton>
                {BtnConfirm && (
                    <StyledButton primary onClick={onConfirm} disabled={loading}>
                        {loading ? "Processando..." : confirmText}
                    </StyledButton>
                )}
            </StyledDialogActions>
        </Dialog>
    );
};