import { API_ENDPOINT, GetHeaders } from './api'

export const uploadReportIncome = async (file, payload) => {
  try {
    const formData = new FormData()
    
    // Verifica se o arquivo é válido
    if (!(file instanceof File)) {
      throw new Error('Arquivo inválido');
    }
    
    // Adiciona o arquivo e os dados ao FormData
    formData.append('file', file);
    formData.append('type', payload.type);
    formData.append('payload', JSON.stringify(payload.payload));
    
    if (payload.companyId) {
      formData.append('companyId', payload.companyId);
    }
    
    if (payload.replace) {
      formData.append('replace', payload.replace);
    }

    const { headers } = await GetHeaders(true);
    delete headers['Content-Type']; // Remove o Content-Type para que o navegador defina automaticamente

    const response = await fetch(`${API_ENDPOINT}/report-incomes/upload`, {
      method: 'POST',
      body: formData,
      headers
    });

    const responseJson = await response.json();
    return responseJson;
    
  } catch (err) {
    console.error('Erro no upload:', err);
    return { 
      error: true, 
      message: err.message || 'Erro ao fazer upload do arquivo' 
    };
  }
} 