import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NotFound from 'screens/NotFound' 

// autenticação
import Login from 'screens/Authentication/Login' 
import Register from 'screens/Authentication/Register' 
import Forgot from 'screens/Authentication/Forgot' 
import CreatePassword from 'screens/Authentication/CreatePassword' 

// Home
import DashboardHome from 'screens/Dashboard/Home' 
import DashboardMe from 'screens/Dashboard/Me' 

// Holerite
import DashboardListPaycheck from "screens/Dashboard/List/Paycheck";
import DashboardFormPaycheck from "screens/Dashboard/Form/Paycheck";
import DashboardFormPaycheckAdd from "screens/Dashboard/Form/PaycheckAdd";

import DashboardFormConfig from "screens/Dashboard/Form/Config";

import DashboardListRevenues from "screens/Dashboard/List/Revenues";
import DashboardFormRevenueAdd from "screens/Dashboard/Form/RevenueAdd";

import DashboardListUsers from "screens/Dashboard/List/Users";
import DashboardListEmployee from "screens/Dashboard/List/Employee";
import DashboardListLogs from "screens/Dashboard/List/Logs";

import DashboardFormUsers from "screens/Dashboard/Form/Users";
import DashboardFormEmployee from "screens/Dashboard/Form/Employee";
import DashboardFormPaycheckUser from "screens/Dashboard/Form/PaycheckUser";
import DashboardFormRevenue from "screens/Dashboard/Form/Revenue/index.js";
import FirstAccess from "screens/Authentication/FirstAccess";
import FinishedFirstAcess from "screens/Authentication/FinishedFirstAcess";

// férias
import DashboardListVacantion from "screens/Dashboard/List/Vacantion";
import DashboardFormVacantion from "screens/Dashboard/Form/Vacantion";


export default function AppRouter() {
    return (
      <Router>  
        <div>
          <Switch>
            <Route path="/" exact> <Login /> </Route> 
            <Route path="/login" exact> <Login /> </Route> 
            <Route path="/register" exact> <Register /> </Route> 
            <Route path="/forgot" exact> <Forgot /> </Route> 
            <Route path="/create-password" exact> <CreatePassword /> </Route> 
            <Route path="/firts-access" exact> <FirstAccess /> </Route>
            <Route path="/finished-first-access/:sessionId" exact> <FinishedFirstAcess /> </Route>
            
            <Route path="/dashboard" exact> <DashboardHome /> </Route> 
            <Route path="/dashboard/config" exact> <DashboardFormConfig /> </Route> 

            <Route path="/dashboard/paycheck" exact> <DashboardListPaycheck /> </Route> 
            <Route path="/dashboard/paycheck/form/:id" exact> <DashboardFormPaycheck /> </Route> 
            <Route path="/dashboard/paycheck/add" exact> <DashboardFormPaycheckAdd /> </Route> 
            <Route path="/dashboard/paycheck/add/:id" exact> <DashboardFormPaycheckAdd /> </Route> 
            <Route path="/dashboard/paycheck/form" exact> <DashboardFormPaycheck /> </Route> 
            <Route path="/dashboard/paycheck/user/:id" exact> <DashboardFormPaycheckUser /> </Route> 
            
            <Route path="/dashboard/revenues" exact> <DashboardListRevenues /> </Route> 
            <Route path="/dashboard/revenues/form/:id" exact> <DashboardFormRevenue /> </Route> 
            <Route path="/dashboard/revenues/add" exact> <DashboardFormRevenueAdd /> </Route> 
            <Route path="/dashboard/revenues/add/:id" exact> <DashboardFormRevenueAdd /> </Route>             
            
            <Route path="/dashboard/vacantion" exact> <DashboardListVacantion /> </Route> 
            <Route path="/dashboard/vacantion/add" exact> <DashboardFormVacantion /> </Route> 
            <Route path="/dashboard/vacantion/add/:id" exact> <DashboardFormVacantion /> </Route> 

            <Route path="/dashboard/employee" exact> <DashboardListEmployee /> </Route> 
            <Route path="/dashboard/employee/add" exact> <DashboardFormEmployee /> </Route> 
            <Route path="/dashboard/employee/add/:id" exact> <DashboardFormEmployee /> </Route> 
            
            <Route path="/dashboard/users" exact> <DashboardListUsers /> </Route> 
            <Route path="/dashboard/users/add" exact> <DashboardFormUsers /> </Route> 
            <Route path="/dashboard/users/add/:id" exact> <DashboardFormUsers /> </Route> 
            
            <Route path="/dashboard/logs" exact> <DashboardListLogs /> </Route> 
            <Route path="/dashboard/me" exact> <DashboardMe /> </Route> 
            
            <Route path="*" exact> <NotFound /> </Route>
          </Switch>
        </div>
      </Router>
    );
}