import { createTheme } from '@mui/material/styles'; 

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#0C71C3', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#49BF00',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#0C71C3',
    },
    error: {
      main: '#FF0000',
      border: '#EB9C9C',
      background: '#fff0ee',
    },
    success: {
      main: '#49BF00',
      border: '#9EFE7E',
      background: '#F5FFEB',
    },

    blue: {
      main: '#559CD5',
      contrastText: '#ffffff',
    },

    green: {
      main: '#0CC3B8',
      contrastText: '#ffffff',
    },

    red: {
      main: '#E05F86',
      contrastText: '#ffffff',
    },

    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    colors:{
        white: '#ffffff',
        black: '#000000',
        text: '#111618',
        grey: '#4C4C4C',
        backgroundgrey: '#F7F7F7',
        backgrounddarkgrey: '#eeeeee',
        lightgrey: '#A4A4A4',
        shadow: 'rgba(0,0,0,.16)',
        lightshadow: 'rgba(112,112,112,.06)'
    }
  },
});  