import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    Wrapper
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoFirstAccess, DoForgotPassword } from "services/authentication";
import { exposeStrapiError } from "utils";

export default function FirstAccess() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});

    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {
        if (!formValue('cnpj') || !formValue('cnpj').length) {
            if (verbose) { toast.error('Preencha o campo: CNPJ') };
            return false;
        }

        if (!formValue('reg') || !formValue('reg').length) {
            if (verbose) { toast.error('Preencha o campo: Número de registro') };
            return false;
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) { return; }
        setLoading(true);

        const result = await DoFirstAccess({ cnpj: formValue('cnpj')?.replace(/ /g, ''), reg: formValue('reg')?.replace(/ /g, '') });
        console.log("🚀 ~ action ~ result:", result);

        setLoading(false);

        if (result && result.sessionId) {
            navigate(`finished-first-access/${result.sessionId}`);
        }
    }

    return (
        <>
            <ContainerUnauthenticated>
                <FormTitle>Primeiro acesso</FormTitle>
                <Wrapper>
                    <Input placeholder="CNPJ (Somente números)" id={'cnpj'} value={formValue('cnpj')} onChange={e => changeForm(e.target.value, 'cnpj')} />
                    <Input placeholder="Número de registro" id={'reg'} value={formValue('reg')} onChange={e => changeForm(e.target.value, 'reg')} />
                </Wrapper>
                <Button primary loading={loading} onClick={action} >Continuar</Button>
                <Button primary outline onClick={() => history.goBack()}>Voltar</Button>
            </ContainerUnauthenticated>
        </>
    );
}

