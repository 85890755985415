import React, { useContext } from 'react'
import { CloseContainer, CompanyDataContainer } from './styled'
import { ModalContainer, ModalContent, Overlay } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import CloseIcon from '@mui/icons-material/Close';

export default function Wrapper({ children }) {
  const { setModal } = useContext(CoreContext)

  const close = () => { setModal(null); }

  const handleClose = (e) => {
    const mc = document.getElementById('modal-content');
    if (!mc?.contains(e?.target) && !(e?.target.tagName === 'LI')) {
      close()
    }
  }
  
  return (
    <>
      <Overlay onClick={handleClose} >
        <ModalContainer>
          <ModalContent id="modal-content">
            <CompanyDataContainer>
              <CloseContainer>
                <CloseIcon 
                  onClick={close}
                  sx={{ 
                    cursor: 'pointer',
                    fontSize: '24px',
                    color: 'colors.grey'
                  }} 
                />
              </CloseContainer>
              {children}
            </CompanyDataContainer>
          </ModalContent>
        </ModalContainer>
      </Overlay>
    </>
  )
}
