import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    
    FormActions, RowReset, RowResetText, 
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import Button from "components/Form/Button";
import { ContentButton } from "ui/styled";
import { Create, Delete, ReadOne, Update } from "services/users";
import { ReadAddressesByZipCode } from "services/api";
import { booleanOptions, exposeStrapiError } from "utils";
import { toast } from "react-toastify";
import { DoForgotPassword } from "services/authentication";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CoreContext } from "context/CoreContext";


export default function DashboardFormsUsers(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()
    const { currentCompany } = useContext(CoreContext)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const refForm = useRef()
    const refFormAddress = useRef()

    const formItems = [
        { placeholder:"CPF", ref:"cpf", half:true, mask:'999.999.999-99' },
        { placeholder:"Nome do usuário", ref:"username", half:true},
        { placeholder:"E-mail", ref:"email" },
        { placeholder:"DDD+Celular", ref:"phone", mask:'(99) 99999-9999' },
        { placeholder:"Empresa com acesso", ref:"enterpriseName" },
        // { placeholder:"Cargo", ref:"office", half:true },
        // { placeholder:"Setor (Opcional)", ref:"department", half:true },
        // { placeholder:"Usuário de Gerência", ref:"isManager", options:booleanOptions },
    ]

    const addressItems = [
        { placeholder:"Cep (Opcional)", ref:"zipcode", mask:'99999-999', onBlur:value => fillAddress(value) },
        { placeholder:"Endereço (Opcional)", ref:"street" },
        { placeholder:"Número (Opcional)", ref:"number" },
        // { placeholder:"Complemento (Opcional)", ref:"complement", quarter:true },
        // { placeholder:"Estado (Opcional)", ref:"state", quarter:true, options:[] },
        // { placeholder:"Cidade (Opcional)", ref:"city", quarter:true, options:[] },
        { placeholder:"Estado (Opcional)", ref:"state" },
        { placeholder:"Cidade (Opcional)", ref:"city" },
        { placeholder:"Bairro (Opcional)", ref:"neighborhood" },
    ]
    
    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [sending, setSending] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.id){
            const normalResult = (result)
            setRegister(normalResult)
        }
        setLoading(false)
    }

    const askPassword = async () => {
        if(register.email){
            setSending(true)
            const result = await DoForgotPassword({ email: register.email })
            setSending(false)
            if(result && !exposeStrapiError(result)){
                toast.success("Link enviado com sucesso")
            } else {
                toast.error("Erro ao enviar link, tente novamente mais tarde")
            }
        } else {
            toast.error("Informe o email para enviar a senha")
        }
    }

    const save = async () => {
        let payload = {
            ...refForm.current.getForm(),
            address: {
                ...refFormAddress.current.getForm()
            },
        }

        payload = {
            ...payload,
            cpf: payload.cpf?.replace(/\D/g, ''),
            phone: payload.phone?.replace(/\D/g, ''),
            address: {
                ...payload.address,
                zipcode: payload.address?.zipcode?.replace(/\D/g, '')
            }
        }

        if(!valid(payload, formItems)){ toast.error("Preencha os dados pessoais"); return ;}
        if(!valid(payload.address, addressItems)){ toast.error("Preencha o endereço"); return ;}

        setSaving(true)
        const result = id ? await Update({ ...payload }, id) : await Create({ 
            ...payload,
            username: payload?.email?.replace(/ /g,''),
            email: payload?.email?.replace(/ /g,''),
            confirmed: true,
            blocked: false,
            currentCompany: currentCompany?.id,
            companies: [currentCompany?.id],
            password: 'Assecont123456@',
            isManager: true,
            role: 1
        })

        if(result && !exposeStrapiError(result)){
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, items) => {  
        const requiredItems = items.filter(item => !item.placeholder.includes("Opcional"));
    
        for (let item of requiredItems) {
            if (!payload?.[item.ref]) {
                toast.error(`Campo obrigatório não preenchido: ${item.placeholder}`);
                return false;
            }
        }
        return true;
    }
    

    const fillAddress = async zipCode => {
        const result = await ReadAddressesByZipCode(zipCode?.replace(/\.|\-/g,''))
        if(result){
            let dinamicAddress = {}
            if(result?.bairro){ dinamicAddress = { ...dinamicAddress, neighborhood: result?.bairro } ;}
            if(result?.uf){ dinamicAddress = { ...dinamicAddress, state: result?.uf } ;}
            if(result?.localidade){ dinamicAddress = { ...dinamicAddress, city: result?.localidade } ;}
            if(result?.logradouro){ dinamicAddress = { ...dinamicAddress, street: result?.logradouro } ;}
            if(result?.cep){ dinamicAddress = { ...dinamicAddress, zipcode: result?.cep } ;}
            setRegister({
                ...register,
                ...refForm.current.getForm(),
                address:{
                    ...register?.address,
                    ...refFormAddress.current.getForm(),
                    ...dinamicAddress
                }
            })
        }
    }

    const remove = async () => {
        setDeleting(true)
        const result = await Delete(id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack()
    }

    useEffect(() => {
        if(id){
            init()
        }
    }, [id])

    return ( 
        <>
            <DashboardCardWrapper backable title={"Cadastro de usuários"} actions={actions} loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                
                <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                    <DashboardFormsCore formItems={formItems} register={register} ref={refForm} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Endereço"} white>
                    <DashboardFormsCore formItems={addressItems} register={register?.address} ref={refFormAddress} />
                </DashboardCardWrapper>
                
                <DashboardCardWrapper closeable title={"Senha"} white>
                    <RowReset>
                        <RowResetText>O usuário receberá um e-mail com link para redefinição de senha</RowResetText>

                        <ContentButton>
                            <Button nospace primary outline loading={sending} onClick={askPassword}>Redefinir senha</Button>
                        </ContentButton>
                    </RowReset>
                </DashboardCardWrapper>
                
                <FormActions>
                    <ContentButton>
                        <Button nospace link color={"error"} loading={deleting} onClick={remove}>Excluir</Button>
                    </ContentButton>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}