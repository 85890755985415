import React from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import DashboardFormsUsers from "components/Dashboard/Forms/Users";

export default function DashboardFormUsers(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    return ( 
        <>
            <ContainerAuthenticated page={"users"}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardFormsUsers />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}