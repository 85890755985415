import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/holerites`, params, true);
}

export const CreateLogImport = async (params) => {
    return await POST(`/import-holerites`, params, true);
}

export const ReadAll = async (id, page = 1) => {
    return await GET(
        `/holerites?filters[company]=${id}&filters[isSigned][$eq]=true&sort[signedAt]=desc&fields[0]=signedAt&fields[1]=isSigned&fields[2]=name&fields[3]=competence&pagination[page]=${page}&pagination[pageSize]=100`,
        true
    );
};

export const ReadAllCount = async (id) => {
    return await GET(
        `/holerites/count?filters[company]=${id}&filters[isSigned][$eq]=true`,
        true
    );
};

export const ReadHoleritesGrouped = async (companyId) => {
    return await GET(`/holerites-grouped?companyId=${companyId}`, true);
};


export const ReadAllForEmployee = async (id) => {
    try {
        let allHolerites = [];
        let currentPage = 1;
        let hasNextPage = true;

        while (hasNextPage) {
            console.log(`Buscando página ${currentPage} de holerites do funcionário`);
            const response = await GET(
                `/holerites?filters[employee]=${id}&pagination[page]=${currentPage}&pagination[pageSize]=100`,
                true
            );

            if (response?.data?.length > 0) {
                console.log(`Recebidos ${response.data.length} holerites do funcionário`);
                allHolerites = [...allHolerites, ...response.data];
                hasNextPage = response.data.length === 100;
                currentPage++;
            } else {
                hasNextPage = false;
            }
        }

        console.log(`Total de holerites carregados: ${allHolerites.length}`);
        return { data: allHolerites };
    } catch (error) {
        console.error('Erro ao buscar holerites do funcionário:', error);
        throw error;
    }
};

export const ReadAllImports = async (id) => {
    return await GET(`/import-holerites?&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadHolerite = async (id, cpf, month, year, employee) => {
    return await GET(`/holerites?filters[company]=${id}&filters[cpf]=${cpf}&filters[month]=${month}&filters[year]=${year}&filters[employee]=${employee}`, true);
}

export const Read = async (id) => {
    return await GET(`/holerites?filters[employee]=${id}&populate[0]=employee`, true);
}

export const ReadFullAll = async () => {
    return await GET(`/holerites?populate[0]=employee&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/holerites/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/holerites/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/holerites/${id}`, true);
}
export const DeleteLogImports = async (id) => {
    return await DELETE(`/import-holerites/${id}`, true);
}

export const ReadAllByUploadId = async (uploadId) => {
    try {
        let allHolerites = [];
        let currentPage = 1;
        let keepFetching = true;

        while (keepFetching) {
            const response = await GET(
                `/holerites?filters[uploadId]=${uploadId}&pagination[page]=${currentPage}&pagination[pageSize]=100`,
                true
            );

            if (response?.data?.length > 0) {
                allHolerites = [...allHolerites, ...response.data];
                if (response.data.length < 100) {
                    keepFetching = false;
                }
                currentPage++;
            } else {
                keepFetching = false;
            }
        }

        return { data: allHolerites };
    } catch (error) {
        console.error('Erro ao buscar holerites:', error);
        throw error;
    }
};

export const GeneratePdf = async (ids) => {
    return await POST(`/holerites/generate-pdf`, { ids }, true, true);
}

export const SignHolerite = async (employeeId, competence) => {
    return await PUT(`/holerites/sign-by-employee?employee=${employeeId}&competence=${competence}`, {}, true);
}

