import React, { useContext, useEffect, useState } from "react";
import {} from "./styled";
import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { parseDatestringUSBR } from "utils/parsers";
import {
  Read,
  ReadByUploadId,
  ReadAllForEmployee,
  GeneratePdf,
} from "services/vacations";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import DashboardActions from "components/Dashboard/Actions";
import moment from "moment";
import { toast } from "react-toastify";

export default function DashboardListsVacantion() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const [activeTab, setActiveTab] = useState(0);
  const { searchExpression, currentCompany, user } = useContext(CoreContext);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);

  const actions = [
    { title: "Novo", action: () => navigate("dashboard/vacantion/add") },
  ];

  const actionsSingle = [
    {
      title: "Download",
      action: (uploadId) => downloadByUploadId(uploadId),
      loadable: true,
    },
  ];

  if (user?.isManager) {
    actionsSingle.push({
      title: "Excluir",
      //action: (holeriteId) => deleteSinglePaycheck(holeriteId),
      loadable: true,
    });
  }

  const columns = [
    { title: "Data Inicial", ref: "initialDate" },
    { title: "Data Final", ref: "endDate" },
    { title: "CPF", ref: "cpf" },
    { title: "Nome do funcionário", ref: "name" },
    { title: "Data e hora da criação", ref: "createDate" },
    { title: "Usuário que importou", ref: "importingUser" },
    {
      title: "",
      ref: "",

      renderCell: (row) => (
        <DashboardActions
          actions={renderCellActions(row)}
          loading={row.loading}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
  ];

  const renderCellActions = (row) => {
    return actionsSingle.map((action) => ({
      ...action,
      action: () => action.action(row.row.uploadId),
    }));
  };

  // ajustes nos campos da lista se necessário
  const parseRegister = (item) => ({
    ...item,
    cpf: formatCPF(item?.cpf || ""),
    name: item.name,
    createDate: moment(item.createdAt).format("DD/MM/YYYY HH:mm"),
    initialDate: moment(item.initialDate).format("DD/MM/YYYY"),
    endDate: moment(item.endDate).format("DD/MM/YYYY"),
  });

  // formata o cpf
  const formatCPF = (cpf) => {
    return cpf
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  };

  // Funções de download
  const downloadByUploadId = async (uploadId) => {
    try {
      const result = await ReadByUploadId(uploadId);
      const id = result.data.map((row) => row.id);

      if (id.length > 0) {
        const blob = await GeneratePdf(id);
        if (blob) {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `recibo_ferias_${uploadId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          throw new Error("Nenhum blob válido recebido para download.");
        }
      } else {
        toast.error("Nenhum registro encontrado para download.");
      }
    } catch (error) {
      console.error("Erro ao baixar os registros:", error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      const result = await Read(currentCompany?.id);
      if (result && !exposeStrapiError(result)) {
        const normalRegister = normalizeStrapiList(result) || [];
        setRows(
          normalRegister
            ?.filter((f) => user?.isManager || f?.cpf === user?.cpf)
            ?.map(parseRegister)
        );
      }
    } catch (error) {
      console.error("Erro ao carregar os registros:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentCompany?.id) {
      init();
    }
  }, [currentCompany, user]);

  return (
    <>
      <DashboardCardTabs
        title={"Férias"}
        actions={actions}
        active={activeTab}
        setActive={setActiveTab}
      >
        <DashboardCardTable
          rows={rows}
          columns={columns}
          searchExpression={searchExpression}
          loading={loading}
        />
      </DashboardCardTabs>
    </>
  );
}
