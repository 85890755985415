import React, { useState } from "react";
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete'; // Importando o Autocomplete

import { 
    MaterialSelect
} from "./styled";
import { ThemedComponent } from "ui/theme";

export const AutoCompleteSelect = ({
  placeholder,
  options,
  small,
  value,
  onChange,
  onBlur,
  id,
  secondary,
  disabled,
}) => {
  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  const handleChange = (event, newValue) => {
    if (onChange && typeof onChange === 'function') {
      onChange(newValue); // Atualiza o estado pai com o novo valor
    }
    toggleOpen();
  };

  return (
    <>
      <ThemedComponent>
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            id={id}
            color={secondary ? 'secondary' : 'primary'}
          >
            {placeholder}
          </InputLabel>
          <Autocomplete
            id={`autocomplete-${id}`}
            options={options} // Lista de opções
            getOptionLabel={(option) => option?.title || ""} // Exibe a propriedade "title"
            renderInput={(params) => (
              <TextField
                {...params}
                label={!value ? placeholder : ""} // Exibe o placeholder apenas quando não há valor
                color={secondary ? 'secondary' : 'primary'}
                disabled={disabled}
              />
            )}
            size={small ? "small" : "medium"} // Ajusta o tamanho
            value={value} // Valor selecionado
            onChange={handleChange} // Atualiza o valor
            onBlur={onBlur} // Executa ação ao perder o foco
            disableClearable // Evita o botão de limpar
            open={opened}
            onOpen={() => setOpened(true)} // Abre o menu
            onClose={() => setOpened(false)} // Fecha o menu
            isOptionEqualToValue={(option, value) => option?.id === value?.id} // Compara os objetos corretamente
          />
        </FormControl>
      </ThemedComponent>
    </>
  );
};

AutoCompleteSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.object, // Valor selecionado
  small: PropTypes.bool, // Determina o tamanho do componente
  secondary: PropTypes.bool, // Define estilo secundário
  onChange: PropTypes.func.isRequired, // Função chamada ao alterar o valor
  id: PropTypes.string.isRequired, // ID do componente
  onBlur: PropTypes.func, // Evento ao perder o foco
  disabled: PropTypes.bool, // Define se o campo está desabilitado
};

AutoCompleteSelect.defaultProps = {
  placeholder: '',
  options: [],
  value: null, // Valor inicial
  small: false,
  secondary: false,
  onChange: undefined,
  onBlur: null,
  disabled: false,
};

export default AutoCompleteSelect;
