import React, { useContext, useEffect, useRef, useState } from "react";  

import { 
    
} from "./styled";

import DashboardCardTable from "components/Dashboard/Cards/Table";
import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";

import DashboardFeedback from "components/Dashboard/Feedback";
import DashboardFormsCore from "../Core";
import { useHistory, useParams } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { ReadOne } from "services/holerites";
import { normalizeStrapiRegister } from "utils";
import { parseCurrency } from "utils/parsers";

export default function DashboardFormsPaycheckUser(){     

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()
    const refForm = useRef()

    const { searchExpression } = useContext(CoreContext)  

    const columns = [
        { title:'Código', ref:'codigo' },
        { title:'Descrição das verbas', ref:'descricao' },
        { title:'QTDE', ref:'referencia' },
        { title:'Proventos', ref:'vencimento' },
        { title:'Descontos', ref:'desconto' },
    ] 
    
    const [rows, setRows] = useState([ ])

    const columnsTotal = [
        { title:'Total dos proventos', ref:'totalVencimentos' },
        { title:'Total de descontos', ref:'totalDescontos' },
        { title:'Total Líquido', ref:'liquido' }
    ] 
    
    const [rowsTotal, setRowsTotal] = useState([ ])

    const [loading, setLoading] = useState(false) 
    const [feedback, setFeedback] = useState(null)
    const [register, setRegister] = useState(null)

    const formItems = [
        { placeholder:"Nome do funcionário", ref:"name", half:true },
        { placeholder:"CPF", ref:"cpf", half:true },
        { placeholder:"E-mail", ref:"email" },
        { placeholder:"DDD+Celular", ref:"phone" },
        { placeholder:"Salário base", ref:"salary" },
        { placeholder:"Cargo", ref:"department", half:true },
        { placeholder:"Setor (Opcional)", ref:"sector", half:true },
        { placeholder:"CTPS (Opcional)", ref:"ctps", half:true },
        { placeholder:"Sindicato (opcional)", ref:"sindicate", half:true },
    ]

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister( { 
                ...normalResult, 
                department: (normalResult?.employee?.office || ""),
                sector: (normalResult?.employee?.sector || ""),
                salary: parseCurrency(normalResult?.baseSalary || "0")
            })
            if(normalResult?.data?.funcionarioEventos?.length){
                setRows(normalResult?.data?.funcionarioEventos)
            }
            if(normalResult?.data?.funcionarioResumo){
                setRowsTotal([normalResult?.data?.funcionarioResumo])
            }
        }
        setLoading(false)
    } 
    useEffect(() => {
        if(id){
            init()
        }
    }, [id])

    return ( 
        <>
            <DashboardCardWrapper backable title={"Holerite do funcionário"} white>
                {/* <DashboardFeedback message={"Mensagem de erro!"} type={"error"} /> */}
                <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                    <DashboardFormsCore formItems={formItems} register={register} />
                </DashboardCardWrapper>
                <DashboardCardTable rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} />
                <DashboardCardTable rows={rowsTotal} columns={columnsTotal} loading={loading} />
            </DashboardCardWrapper>
        </>
    );
}