import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    
    FormActions, RowReset, RowResetText, 
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import Button from "components/Form/Button";
import { ContentButton } from "ui/styled";
import BasicTable from "components/Form/Table";
import { useParams } from "react-router-dom";
import { Create, Delete, ReadOne, Update } from "services/employee";
import { Create as CreateUser, ReadByEmail, Delete as DeleteUser } from "services/users";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { DoForgotPassword } from "services/authentication";
import { toast } from "react-toastify";
import { Read as ReadPaycheck } from "services/holerites";
import { Read as ReadRevenues } from "services/report-incomes";
import { parseCurrency } from "utils/parsers";
import { ReadAddressesByZipCode } from "services/api";
import { CoreContext } from "context/CoreContext";


export default function DashboardFormsEmployee(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()
    const { currentCompany } = useContext(CoreContext)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const paycheckActions = [
        { title:'Novo', action:() => navigate(`dashboard/paycheck/add/${id}`) }
    ]

    const revenueActions = [
        { title:'Novo', action:() => navigate(`dashboard/revenues/add/${id}`) }
    ]

    const accountTypes = [
        { title:'Conta corrente', id:'1' },
        { title:'Poupança', id:'2' }
    ]

    const refForm = useRef()
    const refFormAddress = useRef()
    const refFormBank = useRef()

    const formItems = [
        { placeholder:"Nome do funcionário", ref:"nome", half:true },
        { placeholder:"CPF", ref:"cpf", half:true, mask:'999.999.999-99' },
        { placeholder:"E-mail", ref:"email" },
        { placeholder:"DDD+Celular", ref:"phone", mask:'(99) 99999-9999' },
        { placeholder:"Salário base", ref:"baseSalary", mask:'9999999999' },
        { placeholder:"Cargo", ref:"office", half:true },
        { placeholder:"Setor (Opcional)", ref:"sector", half:true },
        { placeholder:"CTPS (Opcional)", ref:"ctps", half:true },
        { placeholder:"Sindicato (opcional)", ref:"syndicate", half:true },
    ]

    const addressItems = [
        { placeholder:"Cep (Opcional)", ref:"zipcode", mask:'99999-999', onBlur:value => fillAddress(value) },
        { placeholder:"Endereço (Opcional)", ref:"street" },
        { placeholder:"Número (Opcional)", ref:"number" },
        // { placeholder:"Complemento (Opcional)", ref:"complement", quarter:true },
        // { placeholder:"Estado (Opcional)", ref:"state", quarter:true, options:[] },
        // { placeholder:"Cidade (Opcional)", ref:"city", quarter:true, options:[] },
        { placeholder:"Estado (Opcional)", ref:"state" },
        { placeholder:"Cidade (Opcional)", ref:"city" },
        { placeholder:"Bairro (Opcional)", ref:"neighborhood" },
    ]
    
    const bankItems = [
        { placeholder:"Cód. do banco (Opcional)", ref:"codeBank", twenty:true },
        { placeholder:"Agência (Opcional)", ref:"agencyBank", twenty:true },
        { placeholder:"Conta (Opcional)", ref:"accountBank", twenty:true },
        { placeholder:"Tipo de conta (Opcional)", options:accountTypes, ref:"typeBank", twenty:true },
        { placeholder:"Chave Pix (opcional)", ref:"pixKeyBank", twenty:true },
    ]

    const columns = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do funcionário', ref:'name' },
        { title:'Cargo', ref:'jobRole' },
        { title:'Departamento', ref:'departament' },
        { title:'Competência', ref:'competence' },
        { title:'Proventos', ref:'provents' },
    ] 

    const [rows, setRows] = useState([  ])

    const columnsRevenue = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do funcionário', ref:'name' },
        { title:'Cargo', ref:'jobRole' },
        { title:'Departamento', ref:'departament' },
        { title:'Competência', ref:'competence' },
        { title:'Proventos', ref:'provents' },
    ] 

    const [rowsRevenue, setRowsRevenue] = useState([  ])

    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [sending, setSending] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister(normalResult)
            await initPaycheck();
            await initRevenue();
        }
        setLoading(false)
    }
      
    const initPaycheck = async () => {
        const result = await ReadPaycheck(id)
        if( result ){
            const normalRegister = normalizeStrapiList(result) || []
            setRows(normalRegister.map(item => {
                
                const employee = normalizeStrapiRegister(item.employee)

                return ({ 
                    ...item,

                    cpf: employee?.cpf || "", 
                    name:  employee?.nome || "", 
                    departament:  employee?.office || "", 
                    jobRole:  employee?.sector || "", 

                    competence: item?.competence,
                    provents: parseCurrency( item?.baseSalary || "0" ), 
                    action:() => navigate(`dashboard/paycheck/user/${ item.id }`),

                    employee
                })
            }))
        }
    }
      
    const initRevenue = async () => {
        const result = await ReadRevenues(id)
        if( result ){
            const normalRegister = normalizeStrapiList(result) || []
            setRowsRevenue(normalRegister.map(item => {

                const employee = normalizeStrapiRegister(item.employee)

                return ({ 
                    ...item,
    
                    cpf: employee?.cpf || "", 
                    name:  employee?.nome || "", 
                    departament:  employee?.office || "", 
                    jobRole:  employee?.sector || "", 
    
                    competence: item?.exercise,
                    provents: parseCurrency( item?.type || "0" ), //baseSalary

                    employee
    
                })
            }))
        }
    }

    const askPassword = async () => {
        if(register.email){
            setSending(true)
            const result = await DoForgotPassword({ email: register.email })
            setSending(false)
            if(result && !exposeStrapiError(result)){
                toast.success("Link enviado com sucesso")
            } else {
                toast.error("Erro ao enviar link, tente novamente mais tarde")
            }
        } else {
            toast.error("Informe o email para enviar a senha")
        }
    }

    const save = async () => {
        const payload = {
            ...refFormBank.current.getForm(),
            ...refForm.current.getForm(),
            company: currentCompany?.id,
            address: {
                ...refFormAddress.current.getForm()
            },
        }

        if(!valid(payload, formItems)){ 
            toast.error("Preencha os campos obrigatórios dos dados pessoais"); 
            return;
        }

        setSaving(true)
        const result = id ? await Update({ data:payload }, id) : await Create({ data:payload })

        if(result && !exposeStrapiError(result)){
            if(!id){
                // creating a user to this employee
                const ok = await CreateUser({
                    ...payload, 
                    username: payload?.email?.replace(/ /g,''),
                    email: payload?.email?.replace(/ /g,''),
                    name: payload?.nome,
                    department: payload?.sector,
                    confirmed:true,
                    blocked:false,
                    currentCompany: currentCompany?.id,
                    companies:[currentCompany?.id],
                    password: `${((new Date()).getTime())}`,
                    role: 1
                })
            }
            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(!item.placeholder?.toLowerCase().includes('opcional') && !payload?.[item?.ref]){
                return false;
            }
        }
        return true;
    } 

    const fillAddress = async zipCode => {
        const result = await ReadAddressesByZipCode(zipCode?.replace(/\.|\-/g,''))
        if(result){
            let dinamicAddress = {}
            if(result?.bairro){ dinamicAddress = { ...dinamicAddress, neighborhood: result?.bairro } ;}
            if(result?.uf){ dinamicAddress = { ...dinamicAddress, state: result?.uf } ;}
            if(result?.localidade){ dinamicAddress = { ...dinamicAddress, city: result?.localidade } ;}
            if(result?.logradouro){ dinamicAddress = { ...dinamicAddress, street: result?.logradouro } ;}
            if(result?.cep){ dinamicAddress = { ...dinamicAddress, zipcode: result?.cep } ;}
            setRegister({
                ...register,
                ...refFormBank.current.getForm(),
                ...refForm.current.getForm(),
                address:{
                    ...register?.address,
                    ...refFormAddress.current.getForm(),
                    ...dinamicAddress
                }
            })
        }
    }

    const remove = async () => {
        setDeleting(true)
        const user = await ReadByEmail(register?.email)
        if(user?.[0]?.id){
            await DeleteUser(user?.[0]?.id)
        }
        const result = await Delete(id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack()
    }

    useEffect(() => {
        if(id){
            init()
        }
    }, [id])

    return ( 
        <>
            <DashboardCardWrapper backable title={"Cadastro de funcionário"} actions={actions} loading={saving}>
                {/* <DashboardFeedback message={"informe de rendimento cadastrado com sucesso"} type={"success"} /> */}
                
                <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                    <DashboardFormsCore formItems={formItems} register={register} ref={refForm} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Endereço"} white>
                    <DashboardFormsCore formItems={addressItems} register={register?.address} ref={refFormAddress} />
                </DashboardCardWrapper>
   
                <DashboardCardWrapper closeable title={"Dados bancários"} white >
                    <DashboardFormsCore formItems={bankItems} register={register} ref={refFormBank} />
                </DashboardCardWrapper>

                {
                    !id ? null : <>
                        <DashboardCardWrapper closeable title={"Senha"} white>
                            <RowReset>
                                <RowResetText>O usuário receberá um e-mail com link para redefinição de senha</RowResetText>
                                <ContentButton>
                                    <Button loading={sending} nospace primary outline onClick={askPassword}>Redefinir senha</Button>
                                </ContentButton>
                            </RowReset>
                        </DashboardCardWrapper>
                
                        <DashboardCardWrapper closeable title={"Holerites do funcionário"} white actions={paycheckActions}>
                            <BasicTable loading={loading} columns={columns} rows={rows} />
                        </DashboardCardWrapper>
                
                        <DashboardCardWrapper closeable title={"Informes de rendimentos do funcionário"} white actions={revenueActions}>
                            <BasicTable loading={loading} columns={columnsRevenue} rows={rowsRevenue} />
                        </DashboardCardWrapper>
                    </>
                }

                <FormActions>
                    <ContentButton>
                        { !id ? null : <Button loading={deleting} nospace onClick={remove} link color={"error"}>Excluir</Button> }
                    </ContentButton>
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>                                
            </DashboardCardWrapper>
        </>
    );
}