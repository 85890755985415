import styled from 'styled-components'  

export const CardHomeChart = styled.div.attrs({ 
})`
    padding: 24px 0 0;
    rect[fill="#ffffff"]{
        fill: transparent !important;
    }
`;

export const CardHomeStatuItem = styled.div.attrs({ 
})`
    flex:1;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 1px 3px ${ props => props.theme.palette.colors.lightshadow };
    background: ${ props => props.theme.palette.colors.white }; 
    border: 1px solid ${ props => props.theme.palette.colors.lightshadow };
    b{
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: ${ props => props.theme.palette.primary.main }; 
        min-height: 41px;
        display:flex;
        align-items: center;
        ${ props => props.limited ? `max-width: 55px;` : `` }
    }
    p{
        margin: 6px 0 0;
        font-size: 18px;
        font-weight: bold;
        color: ${ props => props.theme.palette.primary.text }; 
    }
`;

export const Legend = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
    margin: 0 10px;
`;

export const ColorBox = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${({ color }) => color};
    margin-right: 5px;
`;