import styled from 'styled-components'  

export const CardHome = styled.div.attrs({ 
})`
    background: ${ props => props.white ? props.theme.palette.colors.white : props.theme.palette.colors.backgroundgrey }; 
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
`;
export const CardHomeHeader = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 12px;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightshadow };
    flex-wrap:wrap;
`;
export const CardHomeHeaderTitle = styled.div.attrs({ 
})`
    font-size:18px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.text }; 
`;
export const CardHomeHeaderActions = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 180px;
`;
export const CardHomeBody = styled.div.attrs({ 
})`
`;

export const CardHomeHeaderButton = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const BackButton = styled.img.attrs({ 
    src:'/icons/back.svg'
})`
    cursor: pointer;
`;
export const ChevronButton = styled.img.attrs({ 
    src:'/icons/chevron-black.svg'
})`
    cursor: pointer;
    ${
        props => props.open ? `
            transform:rotate(180deg)
        ` : ``
    }
`;