import { useContext, useEffect, useRef, useState } from "react";

import { } from "./styled";

import DashboardCardTable from "components/Dashboard/Cards/Table";
import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";

import DashboardFeedback from "components/Dashboard/Feedback";
import { useHistory, useParams } from "react-router-dom";
import {
  Delete as Deleteholerites,
  GeneratePdf,
  ReadAllByUploadId,
  SignHolerite
} from "services/holerites";
import { ReadOne } from "services/companies";
import { ReadOne as ReadPaycheck } from "services/holerites.js";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DashboardActions from "components/Dashboard/Actions/index.js";
import { formatNumber } from "utils/parsers.js";
import * as XLSX from 'xlsx';
import { ReadEmployeeByUserId } from "services/employee";


export default function DashboardFormsPaycheck() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const { id } = useParams();

  console.log('ud', id)
  const refForm = useRef();

  const { searchExpression, currentCompany, user } = useContext(CoreContext);
  console.log("🚀 ~ DashboardFormsPaycheck ~ user:", user)

  const actionsMass = [
    ...(user?.isManager ? [
      { title: "Exportar para Excel", action: () => exportToExcel(rows, "Holerites_Exportados"), loadable: false },
      // { title: "Download", action: () => downloadSingle(), loadable: true },
      { title: "Exclusão em lote", action: () => deletePaycheck(), loadable: true },
    ] : []),
  ];
  const actionsSingle = [
    !user?.isManager ? {
      title: "Visualizar e assinar",
      action: (registerId) => downloadSingle(registerId),
      loadable: true
    } : {
      title: "Download",
      action: (registerId) => downloadSingle(registerId),
      loadable: true
    },
    ...(user?.isManager ? [
      {
        title: "Excluir",
        action: (holeriteId) => deleteSinglePaycheck(holeriteId),
        loadable: true
      },
    ] : []),
  ];

  const columns = [
    !user?.isManager ? { title: "Registro", ref: "rg" } : { title: "Registro", ref: "registration" },
    { title: "Nome", ref: "name" },
    { title: "CPF", ref: "cpf" },
    { title: "Valor líquido", ref: "baseSalary" },
    { title: "Data e Hora da assinatura", ref: "signedAt" },
    {
      title: "Assinaturas",
      ref: "isSigned",
      renderCell: (row) => {
        const isSigned = row.row.isSigned === true;

        return !isSigned ? <CloseIcon style={{ color: 'red' }} /> : <CheckIcon style={{ color: 'green' }} />;
      }
    },
    {
      title: "", ref: "",
      renderCell: (row) => {
        // console.log("Current row data in table:", row)
        const updatedActions = actionsSingle.map(action => ({
          ...action,
          action: () => {
            // console.log('Action ID:', row.row.id);
            action.action(row.row.id);
          }
        }));

        return <DashboardActions actions={updatedActions} loading={row.loading} />
      }
    },
  ];

  const [footerData, setFooterData] = useState({
    registration: "Total",
    totalValue: "0"
  });


  const [rows, setRows] = useState([]);
  console.log("🚀 ~ DashboardFormsPaycheck ~ rows:", rows)

  const columnsTotal = [
    { title: "Total dos proventos", ref: "totalVencimentos" },
    { title: "Total de descontos", ref: "totalDescontos" },
    { title: "Total Líquido", ref: "liquido" },
  ];

  const [rowsTotal, setRowsTotal] = useState([]);

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [registers, setRegisters] = useState([]);
  const [companyData, setCompanyData] = useState(null);

  const formItems = [
    { placeholder: "Matrícula", ref: "registration", disabled: true },
    { placeholder: "Competência", ref: "competence", disabled: true },
    { placeholder: "Nome do funcionário", ref: "name", disabled: true },
    { placeholder: "RG", ref: "rg", disabled: true },
    { placeholder: "CPF", ref: "cpf", disabled: true },
    { placeholder: "Salário base", ref: "salary", disabled: true },
    { placeholder: "DDD+Celular", ref: "phone", disabled: true },
    { placeholder: "Ag./Conta corrente", ref: "agencyAccount", disabled: true },
    { placeholder: "E-mail", ref: "email", disabled: true },
    { placeholder: "Cargo", ref: "office", disabled: true },
    { placeholder: "Departamento", ref: "department", disabled: true },
    { placeholder: "Sindicato", ref: "sindicate", disabled: true },
    { placeholder: "DEP IR", ref: "depIr", disabled: true },
    { placeholder: "DEP SF", ref: "depSf", disabled: true },
    { placeholder: "CTPS", ref: "ctps", disabled: true },
  ];

  const exportToExcel = (rows, fileName) => {
    const rowsData = rows.map(row => ({
      Registro: row.registration,
      Nome: row.name,
      CPF: row.cpf,
      "Valor líquido": row.baseSalary || "0,00",
      "Data e Hora": row.signedAt,
      Assinaturas: row.isSigned ? "Sim" : "Não"
    }));

    const dataWithTotal = [
      ...rowsData,
      {},
      {
        Registro: "TOTAL DA EMPRESA",
        Nome: "",
        CPF: "",
        "Valor líquido": calculateTotal(rows),
        "Data e Hora": "",
        Assinaturas: ""
      }
    ];

    const ws = XLSX.utils.json_to_sheet(dataWithTotal);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Holerites");

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const downloadSingle = async (registerId) => {

    if (!user?.isManager) {
      const userId = user?.id;
      const competence = rows[0]?.competence;
      
      const response = await ReadEmployeeByUserId(userId);
      // console.log("🚀 ~ downloadSingle ~ result:", response)

      const employeeId = response.data[0]?.id
      // console.log("🚀 ~ downloadSingle ~ employeeId:", employeeId)

      if (!employeeId || !competence) {
        toast.error("Erro: Não foi possível determinar o ID do usuário ou a competência.");
        return;
      }

      try {
        const response = await SignHolerite(employeeId, competence);
        console.log("🚀 ~ downloadSingle ~ response:", response);

        await init();
      } catch (error) {
        console.error("Erro ao assinar o holerite:", error);
        toast.error("Erro ao assinar o holerite: " + error.message);
        return;
      }
    }

    try {
      const blob = await GeneratePdf(registerId);
      if (blob) {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'holerites.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        throw new Error('No valid blob received for download.');
      }
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };

  const downloadMass = async (rows) => {
    if (rows.length > 0) {
      const ids = rows.map(row => row.id);
      try {
        const blob = await GeneratePdf(ids);
        if (blob) {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'holerites.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          throw new Error('No valid blob received for download.');
        }
      } catch (error) {
        console.error('Error downloading the PDF:', error);
        toast.error("Erro ao gerar o PDF: " + error.message);
      }
    } else {
      toast.error("Nenhum holerite encontrado para download.");
    }
  };

  const deletePaycheck = async () => {
    setLoading(true);
    try {
      const result = await ReadAllByUploadId(id);
      if (result?.data?.length > 0) {
        const totalHolerites = result.data.length;
        let deletedCount = 0;
        let hasError = false;

        // Deletar em lotes menores para evitar sobrecarga
        const batchSize = 10;
        for (let i = 0; i < result.data.length; i += batchSize) {
          const batch = result.data.slice(i, i + batchSize);
          const deletePromises = batch.map(holerite => 
            Deleteholerites(holerite.id)
              .then(() => {
                deletedCount++;
                console.log(`Progresso: ${deletedCount}/${totalHolerites} holerites deletados`);
              })
              .catch(error => {
                console.error(`Erro ao deletar holerite ${holerite.id}:`, error);
                hasError = true;
              })
          );

          // Aguarda o lote atual terminar antes de continuar
          await Promise.all(deletePromises);
        }

        // Verifica se todos foram deletados com sucesso
        if (hasError) {
          toast.error("Alguns holerites não puderam ser excluídos. Por favor, tente novamente.");
        } else if (deletedCount === totalHolerites) {
          toast.success("Exclusão de holerites realizada com sucesso!");
          
          // Verifica se ainda existem holerites
          const checkResult = await ReadAllByUploadId(id);
          if (!checkResult?.data?.length) {
            // Só volta para a listagem se realmente não houver mais holerites
            history.goBack();
          } else {
            // Se ainda existirem holerites, tenta novamente
            console.log("Ainda existem holerites, tentando excluir novamente...");
            await deletePaycheck();
          }
        }
      } else {
        toast.error("Nenhum holerite encontrado para excluir.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao tentar excluir holerites.");
    } finally {
      setLoading(false);
    }
  };


  const deleteSinglePaycheck = async (holeriteId) => {
    setLoading(true);
    try {
      await Deleteholerites(holeriteId);
      toast.success("Holerite excluído com sucesso!");

      setRegisters(prevRegisters => prevRegisters.filter(register => register.id !== holeriteId));
    } catch (error) {
      console.error(error);
      toast.error("Erro ao tentar excluir o holerite.");
    } finally {
      init()
      setLoading(false);
    }
  }

  const calculateTotal = (registers) => {
    let total = registers.reduce((acc, curr) => {
      let number = (curr.funcionarioResumo.liquido || "0").replace('.', '').replace(',', '.');
      return acc + parseFloat(number);
    }, 0);
    return formatNumber(total);
  };

  const filterRows = (registers, filterText) => {
    if (!filterText) return registers;
    return registers.filter(register =>
      register.registration.toString().toLowerCase().includes(filterText.toLowerCase()) ||
      register.name.toLowerCase().includes(filterText.toLowerCase()) ||
      register.cpf.replace(/\D/g, '').includes(filterText.replace(/\D/g, ''))
    );
  };

  const init = async () => {
    setLoading(true);
    try {
      if (user?.isManager) {
        const result = await ReadAllByUploadId(id);
        const company = await ReadOne(currentCompany?.id);

        if (company) {
          setCompanyData(company.data);
        }

        if (result?.data?.length > 0) {
          const processedRegisters = result.data.map((reg) => {
            const { id, attributes: { data, ...restAttributes } } = reg;

            const name = reg?.attributes?.name?.toLowerCase()
            const email = reg?.attributes?.email?.toLowerCase()

            return {
              id,
              ...restAttributes,
              baseSalary: reg?.attributes?.data?.funcionarioResumo?.liquido,
              signedAt: reg?.attributes?.signedAt ? moment(reg?.attributes?.signedAt).format("DD-MM-YYYY HH:mm") : 'Data e Hora não disponivel',
              isSigned: reg?.attributes?.isSigned,
              name: name,
              email: email,
              rg: data.rg || '',
              salary: data?.funcionarioResumo?.salarioBase,
              sindicate: data.sindicate || '',
              ctps: data.ctps || '',
              depIr: data.depIr || '',
              depSf: data.depSf || '',
              phone: data.phone || '',
              funcionarioEventos: data.funcionarioEventos,
              funcionarioResumo: data.funcionarioResumo,
            };
          });

          setRegisters(processedRegisters);
          setRows(processedRegisters);
          setFooterData({ 
            registration: "TOTAL DA EMPRESA", 
            totalValue: calculateTotal(processedRegisters) 
          });
        } else {
          setFeedback("Nenhum registro encontrado");
        }
      } else {
        const result = await ReadPaycheck(id);
        const company = await ReadOne(currentCompany?.id);

        if (company) {
          setCompanyData(company.data);
        }

        const AllData = result?.data

        console.log('AllData', AllData)

        const processedRegisters = {
          id,
          baseSalary: AllData?.attributes?.data?.funcionarioResumo?.liquido,
          signedAt: AllData?.attributes?.signedAt ? moment(AllData?.attributes?.signedAt).format("DD-MM-YYYY HH:mm") : 'Data e Hora não disponivel',
          isSigned: AllData?.attributes?.isSigned,
          name: AllData?.attributes?.name,
          email: AllData?.attributes?.email,
          rg: AllData?.attributes?.data?.reg || '',
          cpf: AllData?.attributes?.data?.cpf || '',
          salary: AllData?.attributes?.data?.funcionarioResumo?.salarioBase,
          sindicate: AllData?.attributes?.data?.sindicate || '',
          ctps: AllData?.attributes?.data?.ctps || '',
          depIr: AllData?.attributes?.data?.depIr || '',
          depSf: AllData?.attributes?.data?.depSf || '',
          phone: AllData?.attributes?.data?.phone || '',
          funcionarioEventos: AllData?.attributes?.data?.funcionarioEventos,
          funcionarioResumo: AllData?.attributes?.data?.funcionarioResumo,
          competence: AllData?.attributes?.competence || '',
        }


        console.log('processedRegisters', processedRegisters)

        setRows([processedRegisters])
      }
    } catch (error) {
      console.error(error);
      setFeedback("Erro ao buscar registros. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      init();
    }
  }, [id]);

  useEffect(() => {
    const filteredData = filterRows(registers, searchExpression);
    setRows(filteredData);
  }, [searchExpression, registers]);

  return (
    <>
      {feedback ? <DashboardFeedback message={feedback} type={"error"} /> : null}
      <DashboardCardWrapper
        title={"Holerite cadastrado"}
        actions={actionsMass}
        white
      >
        <DashboardCardTable
          searchExpression={searchExpression}
          columns={columns}
          rows={rows}
          loading={loading}
          footer={user?.isManager ? footerData : []}
          showFooter={true}
          pagination={true}
        />
      </DashboardCardWrapper>
    </>
  );
}
