import React from "react";

import {

} from "./styled";

import DashboardCardWrapper from "../Wrapper";
import BasicTable from "components/Form/Table";

export default function DashboardCardTable({ title, rows, columns, searchExpression, loading, hideHeader, footer, showFooter, pagination }) {

    const filterExpression = item => {
        return (!searchExpression || Object.keys(item).filter(k => `${item[k]}`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1).length > 0)
    }

    return (
        <>
            <DashboardCardWrapper title={title} >
                <BasicTable
                    loading={loading}
                    columns={columns}
                    rows={rows?.filter(filterExpression)}
                    hideHeader={hideHeader}
                    footer={footer}
                    showFooter={showFooter}
                    pagination={pagination}
                />
            </DashboardCardWrapper>
        </>
    );
}