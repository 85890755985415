import React, { useState } from "react";  

import { 
    ErrorMessage, 
    ErrorMessageIcon, 
    ErrorMessageText, 

    SuccessMessage, 
    SuccessMessageClose, 
    SuccessMessageIcon, 
    SuccessMessageText, 
} from "./styled";



export default function DashboardFeedback({ message, type}){   

    const [open, setOpen] = useState(true)

    return !open ? null : ( 
        <>
            {
                type === 'success' ? 
                    <SuccessMessage>
                        <SuccessMessageIcon />
                        <SuccessMessageText>{ message }</SuccessMessageText>
                        <SuccessMessageClose onClick={() => setOpen(false)} />
                    </SuccessMessage>
                : null
            }

            {
                type === 'error' ? 
                    <ErrorMessage>
                        <ErrorMessageIcon />
                        <ErrorMessageText>{ message }</ErrorMessageText>
                        <SuccessMessageClose onClick={() => setOpen(false)} />
                    </ErrorMessage>
                : null
            }


        </>
    );
}