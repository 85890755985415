import { useContext, useEffect, useState } from "react";
import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { CoreContext } from "context/CoreContext";
import { Read, ReadWithPagination } from "services/logs";
import { exposeStrapiError } from "utils";
import { formateHourBR, parseDatestringUSBR } from "utils/parsers";

export default function DashboardListsLogs() {
    const { searchExpression, currentCompany } = useContext(CoreContext)
    const [activeTab, setActiveTab] = useState(0)
    const actions = [{ title: 'Exportar', action: () => exportExcel() }]

    // Mapeamento completo de ações para descrições amigáveis
    const actionMap = {
        // Company
        'company_view': 'Visualização de Empresa',
        'company_create': 'Criação de Empresa',
        'company_update': 'Atualização de Empresa',
        'company_delete': 'Exclusão de Empresa',

        // Dashboard
        'dashboard_access': 'Acesso ao Dashboard',

        // Employee
        'employee_view': 'Visualização de Funcionário',
        'employee_create': 'Criaç��o de Funcionário',
        'employee_update': 'Atualização de Funcionário',
        'employee_delete': 'Exclusão de Funcionário',

        // Event
        'event_view': 'Visualização de Evento',
        'event_create': 'Criação de Evento',
        'event_update': 'Atualização de Evento',
        'event_delete': 'Exclusão de Evento',

        // Holerite
        'holerite_list': 'Listagem de Holerites',
        'holerite_list_grouped': 'Listagem Agrupada de Holerites',
        'holerite_view_pdf': 'Visualização de PDF de Holerite',
        'holerite_generate_pdf': 'Geração de PDF de Holerite',
        'holerite_create': 'Criação de Holerite',
        'holerite_update': 'Atualização de Holerite',
        'holerite_delete': 'Exclusão de Holerite',
        'holerite_upload': 'Upload de Holerite',
        'holerite_process': 'Processamento de Holerite',

        // Holiday
        'holiday_view': 'Visualização de Feriado',
        'holiday_create': 'Criação de Feriado',
        'holiday_update': 'Atualização de Feriado',
        'holiday_delete': 'Exclusão de Feriado',

        // Report Income
        'report_income_view': 'Visualização de Informe de Rendimentos',
        'report_income_create': 'Criação de Informe de Rendimentos',
        'report_income_update': 'Atualização de Informe de Rendimentos',
        'report_income_delete': 'Exclusão de Informe de Rendimentos',

        // Vacation
        'vacation_view': 'Visualização de Férias',
        'vacation_create': 'Solicitação de Férias',
        'vacation_update': 'Atualização de Férias',
        'vacation_delete': 'Exclusão de Férias'
    };

    const columns = [
        { title: 'Data', ref: 'date' },
        { title: 'Hora', ref: 'hour' },
        { title: 'Usuário', ref: 'user' },
        { title: 'Ação', ref: 'action' },
        { title: 'Competência', ref: 'competence' }
    ];

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0
    })

    const parseRegister = item => {
        return {
            id: item.id,
            date: parseDatestringUSBR(item.createdAt),
            hour: formateHourBR(item.createdAt),
            user: item.user?.email || 'Sistema',
            action: actionMap[item.action] || 'Ação não mapeada',
            competence: item.details?.competence || '-'
        };
    }

    const loadLogs = async (page = 1) => {
        setLoading(true)
        try {
            const result = await ReadWithPagination(currentCompany?.id, page)
            if (result && !exposeStrapiError(result)) {
                const normalizedData = result.data || [];
                setRows(normalizedData.map(parseRegister))
                setPagination(result.meta.pagination)
            }
        } catch (error) {
            console.error('Error loading logs:', error)
        }
        setLoading(false)
    }

    const handlePageChange = (newPage) => {
        loadLogs(newPage)
    }

    const exportExcel = () => {
        const csvHeader = columns.map(col => col.title);
        const csvRows = rows.map(row => [
            row.date,
            row.hour,
            row.user,
            row.action,
            row.competence
        ].join(','));

        const csvContent = [csvHeader.join(','), ...csvRows].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `logs_${currentCompany?.fantasyName}_${new Date().toISOString()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (currentCompany?.id) {
            loadLogs();
        }
    }, [currentCompany])

    return (
        <DashboardCardTabs 
            title="Histórico de atividades" 
            actions={actions} 
            active={activeTab} 
            setActive={setActiveTab}
        >
            {activeTab === 0 && (
                <DashboardCardTable 
                    rows={rows} 
                    columns={columns} 
                    searchExpression={searchExpression} 
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    loading={loading} 
                />
            )}
        </DashboardCardTabs>
    );
}