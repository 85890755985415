import { API_ENDPOINT, GetHeaders } from './api'

export const uploadVacation = async (file, payload) => {
    try {
      // Verifica se o arquivo é válido
      if (!(file instanceof File)) {
        throw new Error('Arquivo inválido');
      }
  
      // Adiciona o arquivo e os dados obrigatórios ao FormData
      const formData = new FormData();
      formData.append("file", file);
  
      formData.append('type', payload.type);
      formData.append('payload', JSON.stringify(payload.payload));
      
      if (payload.companyId) {
        formData.append('companyId', payload.companyId);
      }
      
      const { headers } = await GetHeaders(true);
      delete headers['Content-Type']; // Deixe o navegador definir automaticamente
  
      const response = await fetch(`${API_ENDPOINT}/vacations/upload`, {
        method: 'POST',
        body: formData,
        headers,
      });
  
      // Verifica o status da resposta
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Erro no servidor: ${response.status} - ${errorDetails.message}`);
      }
  
      return await response.json();
  
    } catch (err) {
      console.error('Erro no upload:', err);
      return { 
        error: true, 
        message: err.message || 'Erro ao fazer upload do arquivo' 
      };
    }
  };