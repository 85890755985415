import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/companies`, params, true);
}

export const Read = async () => {
    return await GET(`/companies?populate=scheduling`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/companies/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/companies/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/companies/${ id }`, true);
} 
 