import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { LoadingContainer, NoDataContainer, NoDataIcon, NoDataText } from '../../Dashboard/Cards/styled';

// Estilos customizados
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  marginBottom: '8px',
}));

const StyledTable = styled(Table)({
  minWidth: 650,
  borderCollapse: 'separate',
  borderSpacing: 0,
  width: '100%'
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 600,
    borderBottom: `2px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledFooterRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  '& td': {
    borderTop: `2px solid ${theme.palette.divider}`,
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: '12px 8px',
  }
}));

const StyledFooterCell = styled(TableCell)(({ theme }) => ({
  '&.total-label': {
    textTransform: 'uppercase',
    letterSpacing: '0.1px',
  },
  '&.total-value': {
    fontFamily: 'Roboto Mono, monospace',
    fontSize: '14px',
  }
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.common.white,
  '& .MuiTablePagination-toolbar': {
    padding: '8px 16px',
  },
  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
    margin: 0,
  },
}));

export default function BasicTable({ columns, rows, loading, footer, showFooter = false, pagination = false }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableRef = React.useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    
    // Se estiver voltando
    if (page > newPage) {
      setTimeout(() => {
        // Scroll para a tabela
        tableRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'end'
        });
      }, 100);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  // Calcula as linhas da página atual
  const currentPageRows = pagination 
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) 
    : rows;

  // Calcula opções de linhas por página baseado no total de registros
  const getRowsPerPageOptions = (total) => {
    const baseOptions = [10, 25, 50];
    
    // Se tiver menos que 10 registros
    if (total <= 10) {
      return [10];
    }
    
    // Se tiver menos que 25 registros
    if (total <= 25) {
      return [10, 25];
    }
    
    // Se tiver menos que 50 registros
    if (total <= 50) {
      return [10, 25, 50];
    }

    // Se tiver mais que 50 registros
    return baseOptions;
  };

  if (loading) {
    return <LoadingContainer><CircularProgress size={40} /></LoadingContainer>;
  }

  if (!rows?.length) {
    return (
      <NoDataContainer>
        <NoDataIcon><InsertChartIcon /></NoDataIcon>
        <NoDataText>Nenhum registro encontrado</NoDataText>
      </NoDataContainer>
    );
  }

  return (
    <div style={{ width: '100%' }} ref={tableRef}>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              {columns.map((item, key) => (
                <StyledTableCell 
                  key={key} 
                  align="left"
                >
                  {item.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageRows.map((row, index) => (
              <StyledTableRow
                key={index}
                onClick={row.action}
                style={row.action ? { cursor: "pointer" } : undefined}
              >
                {columns.map((item, key) => (
                  <StyledTableCell key={key} align="left">
                    {item?.['renderCell'] ? item.renderCell({ row }) : row?.[item.ref]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
          {showFooter && (
            <TableFooter>
              <StyledFooterRow>
                <StyledFooterCell 
                  colSpan={3} 
                  align='left'
                  className="total-label"
                >
                  {footer.registration}
                </StyledFooterCell>
                <StyledFooterCell 
                  colSpan={2} 
                  align='left'
                  className="total-value"
                >
                  {footer.totalValue}
                </StyledFooterCell>
                <StyledFooterCell colSpan={2} align='left' />
              </StyledFooterRow>
            </TableFooter>
          )}
        </StyledTable>
      </StyledTableContainer>
      
      {pagination && (
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={getRowsPerPageOptions(rows.length)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          SelectProps={{
            native: true,
            inputProps: { 'aria-label': 'Linhas por página' }
          }}
          showFirstButton
          showLastButton
          sx={{
            '.MuiTablePagination-select': {
              marginRight: '32px',
              marginLeft: '8px'
            },
            '.MuiTablePagination-displayedRows': {
              margin: '0 16px'
            },
            borderTop: '1px solid rgba(224, 224, 224, 1)'
          }}
        />
      )}
    </div>
  );
}