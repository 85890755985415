import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    padding: 5,
    margin: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderStyle: "solid",
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
  },
  tableCol: {
    paddingHorizontal: 10,
    paddingVertical: 1,
  },
  title: {
    fontSize: 9,
    textAlign: "center",
    fontWeight: "bold",
  },
  text: {
    fontSize: 6,
    fontWeight: "bold",
  },
  titleTableCol: {
    fontSize: 4,
    fontWeight: "bold",
    textAlign: "center",
  },
  textarea: {
    width: "100%",
    height: "10vh",
    border: 1,
  },
  image: {
    width: 70,
  },
});
