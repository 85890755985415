import { useContext, useEffect, useState } from "react";

import { Create, ReadEmployee } from "services/employee";
import { Create as CreateUser } from "services/users";
import { Create as CreateVacation } from "services/vacations";

import { CoreContext } from "context/CoreContext";

import { ReadOne } from "services/companies";
import { normalizeStrapiRegister } from "utils";
import { toast } from "react-toastify";

export default function useEmployee(){
    
    const { currentCompany } = useContext(CoreContext)

    const [currentLimit, setCurrentLimit] = useState(0)

    const init = async () => {  
        const result = await ReadOne(currentCompany?.id)
        const normalResult = normalizeStrapiRegister(result)
        const normalLimitPlan = normalizeStrapiRegister(normalResult?.limitPlan)
        setCurrentLimit(normalLimitPlan)
    }
    
    const maskCPF = (cpf) => {
        const digitsOnly = cpf.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    const createEmployee = async (name, email, cpf, reg) => {
        const currentCompanyID = currentCompany?.id;
        if (!currentCompanyID) {
            return false;
        }
    
        const filterByCpf = `filters[cpf]=${maskCPF(cpf)}`;
        const filterByReg = `filters[reg]=${reg}`;
        const filters = `${filterByCpf}&${filterByReg}`;
    
        const totalEmployee = await ReadEmployee(`?filters[company]=${currentCompanyID}`);
        const employeeByCpfOrReg = await ReadEmployee(`?${filters}`);
    
        if (!employeeByCpfOrReg?.data?.length) {
            if (totalEmployee?.data?.length >= currentLimit) {
                toast.error("Limite de Funcionários atingido, entre em contato para aumentar o limite do seu plano");
                return false;
            }
    
            const nextYear = new Date();
            nextYear.setFullYear(nextYear.getFullYear() + 1);
    
            const employeeData = { 
                nome: name, 
                cpf: maskCPF(cpf), 
                email, company: currentCompanyID, 
                reg 
            };

            const vacationData = { 
                name, 
                cpf: maskCPF(cpf), 
                company: currentCompanyID, 
                availableDays: 0, 
                usedDays: 0, 
                balanceDays: 0, 
                limit: nextYear 
            };
    
            const createdEmployee = await Create({ data: employeeData });
            
            const createdVacation = await CreateVacation({ data: vacationData });
            
            const createrdUser = await CreateUser({
                username: email?.replace(/ /g,''),
                email: email?.replace(/ /g,''),
                name: name,
                department: "",
                confirmed:false,
                blocked:false,
                currentCompany: currentCompanyID,
                companies:[currentCompanyID],
                password: `${((new Date()).getTime())}`,
                role: 1
            })
            
            console.log("createrdUser", createrdUser, createdEmployee, createdVacation)
            return true;
        }
        return false;
    }

    useEffect(() => {
        init()
    }, [])
    
    return {
        createEmployee
    }
}
