import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { DropPaycheck, DropPaycheckIcon, FormActions, FileName } from "./styled";
import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import UploadFile from "components/Form/UploadFile";
import FilterableSelect from "components/Form/FilterableSelect";
import { CalendarInput } from "components/Form/Calendar";
import { toast } from "react-toastify";
import { ReadByCompany } from "services/employee";
import { CoreContext } from "context/CoreContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { uploadVacation } from 'services/vacation-upload';

export default function DashboardFormsVacation() {
  const history = useHistory();
  const { currentCompany, user } = useContext(CoreContext); // Contexto da empresa atual
  const { id } = useParams();
  const refForm = useRef();

  const [saving, setSaving] = useState(false);

  const actions = [
    { title: "Cancelar", outline: true, action: () => history.goBack() },
    { title: "Salvar", action: () => save(), loadable: true },
  ];

  const [formItems, setFormItems] = useState([]);
  const [selectedInitDate, setSelectedInitDate] = useState(null); const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Estado local para armazenar o valor selecionado
  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesResult = await ReadByCompany(currentCompany?.id);

        if (!employeesResult || employeesResult.length === 0) {
          console.warn("Nenhum funcionário encontrado.");
          setFormItems([]); // Reseta os itens do formulário se não houver resultado
          return;
        }

        console.log("Funcionários recebidos:", employeesResult);

        // Constrói dinamicamente os itens do formulário
        const updatedFormItems = [
          {
            ref: "initialDate",
            placeholder: "Data Inicial",
            custom: (
              <div>
                <label htmlFor="initialDate" style={{ display: "block", marginBottom: "5px", fontSize: "13px" }}>
                  Data Inicial
                </label>
                <CalendarInput
                  id="initialDate"
                  placeholder="Data Inicial"
                  secondary
                  disabled={false}
                  value={selectedInitDate} // Binds to the selectedInitDate state
                  onChange={(formattedDate) => {
                    setSelectedInitDate(formattedDate); // Updates selectedInitDate directly
                  }}
                />
              </div>
            ),
            quarter: true,
          },
          {
            ref: "endDate",
            placeholder: "Data Final",
            custom: (
              <div>
                <label htmlFor="endDate" style={{ display: "block", marginBottom: "5px", fontSize: "13px" }}>
                  Data Final
                </label>
                <CalendarInput
                  id="endDate"
                  placeholder="Data Final"
                  secondary
                  disabled={false}
                  value={selectedEndDate} // Binds to the selectedEndDate state
                  onChange={(formattedDate) => {
                    setSelectedEndDate(formattedDate); // Updates selectedEndDate directly
                  }}
                />
              </div>
            ),
            quarter: true,
          },
          {
            ref: "employees",
            placeholder: "Funcionário",
            custom: (
              <div>
                <label htmlFor="employees" style={{ display: "block", marginBottom: "5px", fontSize: "13px" }}>
                  Funcionário
                </label>
                <FilterableSelect
                  id="employees"
                  options={employeesResult} // Lista de funcionários
                  placeholder={!selectedEmployee ? "Selecione um Funcionário" : ""}
                  small={false}
                  value={selectedEmployee} // Valor atual no campo
                  onChange={(selectedValue) => {
                    setSelectedEmployee(selectedValue); // Atualiza o estado local com o valor selecionado
                  }}
                  disabled={false} // Controle de desativação
                />
              </div>
            ),
            quarter: true,
          },
          {
            placeholder: "Arquivo",
            ref: "file",
            custom: (
              <div>
                <label
                  htmlFor="file"
                  style={{ display: "block", marginBottom: "5px", fontSize: "13px" }}
                >
                  Arquivo de Férias
                </label>
                {selectedFile?.name ? (
                  <DropPaycheck onClick={() => setSelectedFile(null)}>
                    <FileName>{selectedFile?.name}</FileName>
                    <DropPaycheckIcon close />
                  </DropPaycheck>
                ) : (
                  <UploadFile forwardRepo onChange={(e) => setSelectedFile(e)}>
                    <DropPaycheck>
                      <DropPaycheckIcon /> Adicionar arquivo
                    </DropPaycheck>
                  </UploadFile>
                )}
              </div>
            ),
            quarter: true,
          }

        ];


        // Atualiza os itens do formulário com a lista definida
        setFormItems(updatedFormItems);

      } catch (error) {
        console.error("Erro ao buscar funcionários:", error);
      }
    };

    if (currentCompany?.id) {
      fetchEmployees();
    }
  }, [selectedInitDate, selectedEndDate, selectedEmployee, selectedFile, currentCompany]);

  const save = async () => {
    // Validação de campos obrigatórios
    if (!selectedInitDate || !selectedEndDate || !selectedEmployee || !selectedFile) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    if (selectedInitDate > selectedEndDate) {
      toast.error("A data inicial não pode ser maior que a data final.");
      return;
    }

    try {
      setSaving(true);

      const payload = {
        type: "vacation",
        payload: {
          initialDate: selectedInitDate,
          endDate: selectedEndDate,
          employeeId: selectedEmployee.id,
          user: user.email,
          companyId: currentCompany?.id
        }
      };

      const result = await uploadVacation(selectedFile, payload);

      if (result.ok) {
        toast.success("Dados salvos com sucesso!");
        history.goBack(); // Volta à página anterior
      } else {
        const errorMessage = await result.text(); // Obtém a mensagem de erro do backend
        console.error("Erro ao salvar os dados:", errorMessage);
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Erro ao salvar:", error);
      toast.error("Erro ao salvar. Tente novamente mais tarde.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <DashboardCardWrapper
        backable
        title={"Novo Arquivo de Férias"}
        white
      >
        <DashboardFormsCore
          disabled={!!id}
          formItems={formItems}
          ref={refForm}
        />
        <FormActions>
          <DashboardActions actions={actions} loading={saving} />
        </FormActions>
      </DashboardCardWrapper>
    </>
  );
}
