import styled from 'styled-components'  


export const CardHomeHeaderActions = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 210px;
`;

export const HeaderActions = styled.div.attrs({ 
})`
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 100%;
    justify-content: flex-end;
`;
