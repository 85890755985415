
export const parseCurrency = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return `R$ ${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`
}  

export const parseCurrencyMoney = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return `${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`.split(',')[0]
}

export const parseCurrencyCents = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return values[1]
}
 
export const parseDatestringBRUS = datestring => {
    const ds = `${ datestring }`?.split('/')
    return `${ ds[2] }-${ ds[1] }-${ ds[0] }`
}

export const parseDatestringUSBR = datestring => {
    const ds = `${ datestring }`?.split('T')?.[0]?.split('-')
    return `${ ds[2] }/${ ds[1] }/${ ds[0] }`
}

export const formatDateTimeBR = (datestring) => {
    const date = new Date(datestring);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear().toString().substr(-2);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}/${month}/${year}:${hours}:${minutes}:${seconds}`;
};

export const formateHourBR = (datestring) => {
    const date = new Date(datestring);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};

export const formatNumber = (value) => {    
    let result = value.toFixed(2);

    result = result.replace('.', ',');

    return result.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
