import { useContext, useEffect, useRef, useState } from "react";

import {} from "./styled";

import DashboardCardTable from "components/Dashboard/Cards/Table";
import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";

import DashboardFeedback from "components/Dashboard/Feedback";
import { useHistory, useParams } from "react-router-dom";
import { ReadOne } from "services/companies";
import {
  GeneratePdf,
  ReadOne as ReadOneForId,
} from "services/report-incomes.js";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { pdf } from "@react-pdf/renderer";
import DashboardActions from "components/Dashboard/Actions/index.js";
import { formatNumber } from "utils/parsers.js";
import * as XLSX from "xlsx";
import {
  ReadAllByUploadId,
  Delete as DeleteReportIncome,
} from "services/report-incomes.js";
import { IncomeStatement } from "components/GeneratePDF/IncomeStatement/index.js";
import moment from "moment";

export default function DashboardFormsRevenue() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const { id } = useParams();
  console.log("ud", id);
  const refForm = useRef();

  const { searchExpression, currentCompany, user } = useContext(CoreContext);

  const actionsMass = [
    ...(user?.isManager
      ? [
          {
            title: "Exclusão em lote",
            action: () => deletePaycheck(),
            loadable: true,
          },
          {
            title: "Exportar para Excel",
            action: () => exportToExcel(rows, "Informe_Exportados"),
            loadable: false,
          },
        ]
      : []),
  ];

  const actionsSingle = [
    {
      title: "Download",
      action: (registerId) => downloadSingle(registerId),
      loadable: true,
    },
    ...(user?.isManager
      ? [
          {
            title: "Excluir",
            action: (incomeId) => deleteSinglePaycheck(incomeId),
            loadable: true,
          },
        ]
      : []),
  ];

  const columns = [
    { title: "Nome", ref: "name" },
    { title: "CPF", ref: "cpf" },
    {
      title: "",
      ref: "",
      renderCell: (row) => {
        // console.log("Current row data in table:", row)
        const updatedActions = actionsSingle.map((action) => ({
          ...action,
          action: () => {
            console.log("Action ID:", row.row.id);
            action.action(row.row.id);
          },
        }));

        return (
          <DashboardActions actions={updatedActions} loading={row.loading} />
        );
      },
    },
  ];

  const [footerData, setFooterData] = useState({
    registration: "Total",
    totalValue: "0",
  });

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [registers, setRegisters] = useState([]);
  const [companyData, setCompanyData] = useState(null);

  const exportToExcel = (rows, fileName) => {
    const ws = XLSX.utils.json_to_sheet(
      rows.map((row) => ({
        Nome: row.name,
        CPF: row.cpf,
        Preventos: row.provents,
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Informes");

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const downloadSingle = async (registerId) => {
    try {
      const blob = await GeneratePdf(registerId);
      if (blob) {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "informe.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        throw new Error("No valid blob received for download.");
      }
    } catch (error) {
      console.error("Error downloading the PDF:", error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };

  const deletePaycheck = async () => {
    setLoading(true);
    try {
      const result = await ReadAllByUploadId(id);
      if (result?.data?.length > 0) {
        for (const income of result.data) {
          await DeleteReportIncome(income.id);
        }

        toast.success("Exclusão de Informes realizada com sucesso!");
        history.goBack();
      } else {
        toast.error("Nenhum Informe encontrado para excluir.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao tentar excluir Informe.");
    } finally {
      setLoading(false);
    }
  };

  const deleteSinglePaycheck = async (incomeId) => {
    setLoading(true);
    try {
      await DeleteReportIncome(incomeId);
      toast.success("Informe de rendimento excluído com sucesso!");

      setRegisters((prevRegisters) =>
        prevRegisters.filter((register) => register.id !== incomeId)
      );
    } catch (error) {
      console.error(error);
      toast.error("Erro ao tentar excluir o informe de rendimento.");
    } finally {
      init();
      setLoading(false);
    }
  };

  const calculateTotal = (registers) => {
    let total = registers.reduce((acc, curr) => {
      let number = (curr.salary || "0").replace(".", "").replace(",", ".");
      return acc + parseFloat(number);
    }, 0);
    return formatNumber(total);
  };

  const filterRows = (registers, filterText) => {
    if (!filterText) return registers;
    return registers.filter(
      (register) =>
        register.registration
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        register.name.toLowerCase().includes(filterText.toLowerCase()) ||
        register.cpf.replace(/\D/g, "").includes(filterText.replace(/\D/g, ""))
    );
  };

  const init = async () => {
    setLoading(true);
    try {
      if (user?.isManager) {
        const result = await ReadAllByUploadId(id);
        const company = await ReadOne(currentCompany?.id);

        // console.log('result', result)

        if (company) {
          setCompanyData(company.data);
        }

        if (result?.data?.length > 0) {
          const processedRegisters = result.data.map((reg) => {
            const {
              id,
              attributes: { data },
            } = reg;
            return {
              id,
              ...data,
              name: reg.attributes.data.nome,
              cpf: data?.cpf,
              provents: reg.attributes.provents,
            };
          });

          setRegisters(processedRegisters);
          setRows(processedRegisters);
          // Update footerData with the total salary
          setFooterData({
            registration: "TOTAL DA EMPRESA",
            totalValue: calculateTotal(processedRegisters),
          });
        } else {
          setFeedback("Nenhum registro encontrado");
        }
      } else {
        const result = await ReadOneForId(id);

        const company = await ReadOne(currentCompany?.id);

        if (company) {
          setCompanyData(company.data);
        } else {
          setFeedback("Empresa não encontrada");
        }

        const AllData = result?.data;

        console.log("AllData", AllData);

        const processedRegisters = {
          id,
          ...AllData,
          name: AllData.attributes.data.nome,
          cpf: AllData.attributes.cpf,
          provents: AllData.attributes.provents,
        };

        console.log("processedRegisters", processedRegisters);

        setRows([processedRegisters]);
      }
    } catch (error) {
      console.error(error);
      setFeedback("Erro ao buscar registros. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      init();
    }
  }, [id]);

  useEffect(() => {
    const filteredData = filterRows(registers, searchExpression);
    setRows(filteredData);
  }, [searchExpression, registers]);

  return (
    <>
      {feedback ? (
        <DashboardFeedback message={feedback} type={"error"} />
      ) : null}
      <DashboardCardWrapper
        title={"Informe de rendimentos cadastrados"}
        actions={actionsMass}
        white
      >
        <DashboardCardTable
          searchExpression={searchExpression}
          columns={columns}
          rows={rows}
          footer={false}
          showFooter={false}
          pagination={true}
        />
      </DashboardCardWrapper>
    </>
  );
}
