import styled from 'styled-components'  

export const Content = styled.div.attrs({ 
})`
`;

export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;    
`;

export const DropPaycheck = styled.div.attrs({
})`
    padding: 15px 12px;
    border-radius: 8px;
    border: 1px dashed ${props => props.theme.palette.colors.shadow};

    display:flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;
`;

export const DropPaycheckIcon = styled.img.attrs({
    src: '/icons/plus.svg'
})`
    ${props => props.close ? `
        transform: rotate(45deg);
        margin: 0 0 0 auto;
    ` : ``}
`;

export const FileName = styled.span`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;