import React, { useState } from "react";
import PropTypes from 'prop-types';
import { ThemedComponent } from "ui/theme";

import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; // Usando o adaptador Moment.js
import moment from 'moment'; // Importando Moment.js
import 'moment/locale/pt-br'; // Configurando a localização para português do Brasil

// Configurando Moment.js para usar o idioma português
moment.locale('pt-br');

export const CalendarInput = (props) => {
    const [selectedDate, setSelectedDate] = useState(props.value);

    // Função para formatar a data no padrão brasileiro
    const formatToBrazilianDate = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '';
    };

    const handleChange = (newValue) => {
        if (!newValue || !moment(newValue).isValid()) {
            setSelectedDate(''); // Reseta a data se for inválida ou nula
            if (props.onChange) {
                props.onChange(''); // Passa uma string vazia para o callback
            }
            return;
        }

        const formattedDate = formatToBrazilianDate(newValue); // Formata a data para DD/MM/YYYY
        setSelectedDate(formattedDate); // Atualiza o estado com a data formatada
        if (props.onChange) {
            props.onChange(formattedDate); // Passa a data formatada para o callback
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemedComponent>
                <FormControl fullWidth variant="outlined">
                    <DatePicker
                        value={selectedDate ? moment(selectedDate, 'DD/MM/YYYY') : null} // Converte para objeto Moment
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id={props.id}
                                color={props.secondary ? 'secondary' : 'primary'}
                                disabled={props.disabled}
                                onBlur={props.onBlur}
                                placeholder={selectedDate ? '' : props.placeholder} // Remove o placeholder se houver data
                                InputLabelProps={{ shrink: Boolean(selectedDate) }} // Move o rótulo para cima se houver data
                                onKeyDown={(ev) =>
                                    typeof props.onSubmitEditing === 'function'
                                        ? ev.keyCode === 13
                                            ? props.onSubmitEditing()
                                            : null
                                        : props.onKeyDown
                                }
                            />
                        )}
                    />
                </FormControl>
            </ThemedComponent>
        </LocalizationProvider>
    );
};

CalendarInput.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

CalendarInput.defaultProps = {
    placeholder: '',
    value: null, // Inicialmente vazio
    secondary: false,
    disabled: false,
    onBlur: undefined,
    onKeyDown: undefined,
    onSubmitEditing: undefined,
    onChange: undefined,
};
