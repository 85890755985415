import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './router'; // MOD
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { CoreState } from './context/CoreContext'
import ModalCore from 'components/NewModal/Core';

// Obter o elemento root
const rootElement = document.getElementById('root');

// Criar o root usando a nova API createRoot
const root = createRoot(rootElement);

// Renderizar o aplicativo
root.render(
  <React.StrictMode>
    <CoreState>
      <App />
      <ModalCore />
      <ToastContainer />
    </CoreState>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); 