import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/employees`, params, true);
}

export const Read = async (companyId) => {
    try {
        let allEmployees = [];
        let currentPage = 1;
        let hasNextPage = true;

        while (hasNextPage) {
            console.log(`Buscando página ${currentPage} de funcionários`);
            const response = await GET(
                `/employees?filters[companies][id][$eq]=${companyId}&pagination[page]=${currentPage}&pagination[pageSize]=100`,
                true
            );

            if (response?.data?.length > 0) {
                console.log(`Recebidos ${response.data.length} funcionários`);
                allEmployees = [...allEmployees, ...response.data];
                hasNextPage = response.data.length === 100;
                currentPage++;
            } else {
                hasNextPage = false;
            }
        }

        console.log(`Total de funcionários carregados: ${allEmployees.length}`);
        return { data: allEmployees };
    } catch (error) {
        console.error('Erro ao buscar funcionários:', error);
        throw error;
    }
};

export const ReadEmployeeByUserId = async (userId) => {
    return await GET(`/employees?filters[user][id][$eq]=${userId}&pagination[pageSize]=1000`, true);
}

export const ReadEmployee = async (query) => {
    return await GET(`/employees${query}`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/employees/${id}?populate=*`, true);
}

export const ReadByCompany = async (companyId) => {
    try {
        console.log("Buscando funcionários para a empresa:", companyId);

        // Fazer uma única requisição sem paginação
        const response = await GET(
            `/employees?filters[companies][id][$eq]=${companyId}&populate=*`, // Endpoint ajustado
            true
        );

        // Verificar se os dados existem e são válidos
        if (response?.data?.length > 0) {
            console.log(`Recebidos ${response.data.length} funcionários`);

            // Mapear os dados no formato necessário para o Select
            const dropdownOptions = response.data.map((employee) => ({
                id: employee?.id, // Define o 'id' como esperado pelo componente Select
                title: employee?.attributes?.nome || "Nome não disponível", // Define 'title' como rótulo
            }));

            console.log("Opções formatadas para o Select:", dropdownOptions);
            return dropdownOptions; // Retorna a lista formatada
        } else {
            console.warn("Nenhum funcionário encontrado para a empresa:", companyId);
            return []; // Retorna lista vazia se não houver funcionários
        }
    } catch (error) {
        console.error("Erro ao buscar funcionários:", error);
        throw error; // Propaga o erro para ser tratado
    }
};

export const Update = async (params, id) => {
    return await PUT(`/employees/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/employees/${id}`, true);
}
