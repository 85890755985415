import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    DropPaycheck,
    DropPaycheckIcon,
    FileName,
    FormActions,
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import { monthsOptions } from "utils/brazil";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import UploadFile from "components/Form/UploadFile";
import { normalizeStrapiRegister, numerize, typePaycheckOptions, yearOptions } from "utils";
import { UploadImage } from "services/api";
import { toast } from "react-toastify";
import { ReadOne as ReadOneCompany } from "services/companies";
import { CoreContext } from "context/CoreContext";
import useEmployee from "hooks/useEmployee";
import { ConfirmationDialog } from "components/Modal";
import { uploadHolerite } from "services/holerite-upload";


export default function DashboardFormsPaycheckAdd() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const refForm = useRef()
    const { currentCompany, user } = useContext(CoreContext)
    // console.log('currentCompany', currentCompany)
    const { createEmployee, preRegisterEmployee } = useEmployee()

    const actions = [
        { title: 'Cancelar', outline: true, action: () => history.goBack() },
        { title: 'Salvar', action: () => save(), loadable: true },
    ]

    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [showReplaceModal, setShowReplaceModal] = useState(false);
    const [showLimitPayCheckModal, setShowLimitPayCheckModal] = useState(false);
    const [currentLimit, setCurrentLimit] = useState(null);
    const [replaceFile, setReplaceFile] = useState(null);

    console.log('currentLimit', currentLimit)


    const [formItems, setFormItems] = useState([])

    const maskCPF = (cpf) => {
        const digitsOnly = cpf.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    // console.log('currentLimit', currentLimit.limit)

    const generateUniqueUploadId = () => {
        const date = new Date();
        const uniqueId = date.getTime().toString() + Math.floor(Math.random() * 1000).toString();
        return uniqueId;
    };

    const save = async () => {
        try {
            const values = refForm.current.getForm();
            if (!(values['year'] && values['month'] && values['type'])) {
                toast.error("Preencha todos os campos");
                return;
            }
            if (!selectedFile || !(selectedFile instanceof File)) {
                toast.error("Selecione um arquivo válido");
                return;
            }

            setLoading(true);

            const payload = {
                type: "holerite",
                payload: {
                    'tipo-arquivo': currentCompany?.typeSheet || '2',
                    'cnpj': currentCompany?.cnpj?.replace(/[^\d]/g, ''),
                    'competencia': `${numerize(values['month'])}/${values['year']}`,
                    'tipo-holerite': values['type']
                }
            };

            if (!payload.payload.cnpj) {
                throw new Error("CNPJ da empresa não encontrado");
            }

            if (!payload.payload['tipo-arquivo']) {
                throw new Error("Tipo de arquivo não definido");
            }

            const result = await uploadHolerite(selectedFile, payload);

            if (result.message === "Holerite já existe com os dados fornecidos.") {
                setShowReplaceModal(true);
                setLoading(false);
                return;
            }

            // Verifica primeiro se é erro de limite excedido
            if (result.details?.limitExceeded) {
                const errorDetails = result.details;
                setCurrentLimit({
                    current: errorDetails.currentHolerites,
                    limit: errorDetails.currentLimit,
                    new: errorDetails.newHolerites,
                    total: errorDetails.totalHolerites,
                    suggestedPlanId: errorDetails.suggestedPlanId
                });
                setShowLimitPayCheckModal(true);
                setLoading(false);
                return;
            }

            if (!result.success || result.error) {
                throw new Error(result.message || "Erro ao processar o arquivo");
            }

            if (result.success && result.processInfo?.savedRecords > 0) {
                toast.success("Holerites processados e cadastrados com sucesso.");
                history.goBack();
            } else {
                if (result.errors?.length > 0) {
                    throw new Error(result.errors[0].message);
                }
                throw new Error(result.message || "Erro ao processar o arquivo, verifique se os dados estão corretos.");
            }
        } catch (error) {
            console.error('Erro durante o salvamento:', error);
            toast.error(error.message || "Ocorreu um erro inesperado durante o processo.");
        } finally {
            setLoading(false);
        }
    };

    const handleReplaceConfirm = async () => {
        try {
            if (!replaceFile) {
                toast.error("Selecione o novo arquivo para substituição");
                return;
            }

            setLoading(true);
            const values = refForm.current.getForm();
            
            const payload = {
                type: "holerite",
                payload: {
                    'tipo-arquivo': currentCompany?.typeSheet,
                    'cnpj': currentCompany?.cnpj?.replace(/\.|\-|\//g, ''),
                    'competencia': `${numerize(values['month'])}/${values['year']}`,
                    'tipo-holerite': values['type'],
                    'replace': true
                }
            };

            const result = await uploadHolerite(replaceFile, payload);
            console.log("🚀 ~ handleReplaceConfirm ~ result:", result)

            if (result.error) {
                throw new Error(result.message || "Erro ao substituir o holerite");
            }

            toast.success("Holerite substituído com sucesso");
            history.goBack();
        } catch (error) {
            console.error('Erro ao substituir holerite:', error);
            toast.error(error.message || "Erro ao substituir o holerite");
        } finally {
            setLoading(false);
            setShowReplaceModal(false);
            setReplaceFile(null);
        }
    };

    const handleAcpetedChangePlan = async () => {
        try {
            // Aqui você pode adicionar a lógica para atualizar o plano
            // Por exemplo, redirecionar para uma página de upgrade ou chamar uma API
            toast.info("Em breve você será contatado para atualização do plano");
            setShowLimitPayCheckModal(false);
            history.goBack();
        } catch (error) {
            console.error('Erro ao aceitar mudança de plano:', error);
            toast.error("Erro ao processar solicitação de mudança de plano");
        }
    };

    const ReplaceModalContent = () => (
        <>
            <p>Já existe um holerite lançado para este período. Deseja substituir o arquivo existente?</p>
            <UploadFile forwardRepo onChange={e => setReplaceFile(e)}>
                <DropPaycheck>
                    <DropPaycheckIcon /> 
                    {replaceFile?.name || "Adicionar novo arquivo"}
                </DropPaycheck>
            </UploadFile>
        </>
    );

    const LimitPaycheckModalContent = () => (
        <>
            <p>Você atingiu o limite de holerites do seu plano atual.</p>
            <p>Limite do plano atual: {currentLimit?.limit} holerites</p>
            <p>Quantidade atual de holerites: {currentLimit?.current}</p>
            <p>Quantidade que está tentando importar: {currentLimit?.new}</p>
            <p>Total após importação: {currentLimit?.total}</p>
            <p>É necessário atualizar seu plano para continuar.</p>
            <strong>Deseja prosseguir com a atualização do plano?</strong>
        </>
    );

    useEffect(() => {
        setFormItems([
            { placeholder: "Ano", ref: "year", options: yearOptions, quarter: true },
            { placeholder: "Mês", ref: "month", options: [...monthsOptions], quarter: true },
            { placeholder: "Tipo de holerite", ref: "type", options: typePaycheckOptions, quarter: true },
            {
                placeholder: "Holerite",
                ref: "type",
                custom: selectedFile?.name ?
                    <DropPaycheck onClick={() => setSelectedFile(null)}><FileName>{selectedFile?.name}</FileName> <DropPaycheckIcon close /></DropPaycheck>
                    :
                    <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                        <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                    </UploadFile>,
                quarter: true
            }
        ])
    }, [selectedFile])

    const init = async () => {
        try {
            const result = await ReadOneCompany(currentCompany?.id)
            const normalResult = normalizeStrapiRegister(result)
            const normalLimitPlan = normalizeStrapiRegister(normalResult?.limitPlan)
            
            // Guarda os limites atuais
            setCurrentLimit({
                current: normalResult?.employeeCount || 0,
                limit: normalLimitPlan?.limit || 0,
                planId: normalLimitPlan?.id
            });
        } catch (error) {
            console.error('Erro ao carregar dados da empresa:', error);
        }
    };

    useEffect(() => {
        init();
    }, [currentCompany]);

    return (
        <>
            <DashboardCardWrapper backable title={"Novo holerite"} white>
                {feedback ? <DashboardFeedback message={"Holerites cadastrados com sucesso"} type={"success"} /> : null}
                <DashboardFormsCore formItems={formItems} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={loading} />
                </FormActions>
            </DashboardCardWrapper>

            <ConfirmationDialog
                open={showReplaceModal}
                title="Substituir Holerite"
                onCancel={() => {
                    setShowReplaceModal(false);
                    setReplaceFile(null);
                }}
                onConfirm={handleReplaceConfirm}
                btnCancelText='Cancelar'
                btnConfirmText='Confirmar'
            >
                <ReplaceModalContent />
            </ConfirmationDialog>

            <ConfirmationDialog
                open={showLimitPayCheckModal}
                title="Limite de Holerites Excedido"
                onCancel={() => setShowLimitPayCheckModal(false)}
                onConfirm={handleAcpetedChangePlan}
                btnCancelText='Não'
                btnConfirmText='Sim'
            >
                <LimitPaycheckModalContent />
            </ConfirmationDialog>
        </>
    );
}
