import { CardHomeChart, StyledCardContainer, CardHomeStatuItem } from "./styled";
import { Chart } from "react-google-charts";
import DashboardCardWrapper from "../Wrapper";
import { theme } from "ui/theme-color";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingContainer, NoDataContainer, NoDataIcon, NoDataText } from "../styled";

export default function DashboardCardEmployee({ register, loading, filterMonth }) {
    const abbreviateMonth = (monthName) => {
        const monthMap = {
            "janeiro": "jan",
            "fevereiro": "fev",
            "março": "mar",
            "abril": "abr",
            "maio": "mai",
            "junho": "jun",
            "julho": "jul",
            "agosto": "ago",
            "setembro": "set",
            "outubro": "out",
            "novembro": "nov",
            "dezembro": "dez"
        };
        return monthMap[monthName.toLowerCase()] || monthName;
    };

    // Processar os dados mensais para todos os meses
    const monthsData = Array.from({ length: 12 }, (_, index) => {
        const monthNumber = index + 1;
        const monthName = new Date(2024, index).toLocaleString('pt-BR', { month: 'long' });
        
        // Procura nos dados da API
        const monthData = register?.data?.find(item => item.month === monthNumber);
        const totalEmployees = monthData?.count || 0;

        return {
            month: monthNumber,
            name: abbreviateMonth(monthName),
            value: totalEmployees
        };
    });

    // Verificar se tem dados
    const hasData = monthsData.some(item => item.value > 0);

    // Preparar dados para o gráfico - sempre mostra todos os meses
    const data = hasData ? [
        ["Mês", "Total de Funcionários", { role: "style" }],
        ...monthsData.map(item => {
            const isFilteredMonth = filterMonth ? item.month === parseInt(filterMonth) : item.value > 0;
            const barColor = isFilteredMonth ? theme.palette.primary.main : "#E0E0E0";
            const barValue = isFilteredMonth ? item.value : 0;
            
            return [item.name, barValue, barColor];
        })
    ] : [];

    const options = {
        curveType: "function",
        legend: { position: "none" },
        backgroundColor: 'transparent',
        tooltip: { trigger: 'selection' },
        bar: { groupWidth: "30%" },
        chartArea: {
            width: '90%',
            height: '80%',
            top: 10,
            bottom: 30,
            left: 40,
            right: 10
        },
        vAxis: {
            gridlines: {
                color: '#f5f5f5'
            },
            baselineColor: '#f5f5f5',
            minValue: 0,
            format: '0'
        },
        hAxis: {
            gridlines: {
                color: 'transparent'
            },
            baselineColor: '#f5f5f5'
        }
    };

    return (
        <StyledCardContainer>
            <DashboardCardWrapper title={"Quantidade de Funcionários por Mês"}>
                <CardHomeChart>
                    {loading ? (
                        <LoadingContainer>
                            <CircularProgress size={40} />
                        </LoadingContainer>
                    ) : hasData ? (
                        <Chart
                            chartType="Bar"
                            width="100%"
                            height="250px"
                            data={data}
                            options={options}
                        />
                    ) : (
                        <NoDataContainer>
                            <NoDataIcon>
                                <InsertChartIcon />
                            </NoDataIcon>
                            <NoDataText>
                                Nenhum registro encontrado
                            </NoDataText>
                        </NoDataContainer>
                    )}
                </CardHomeChart>
            </DashboardCardWrapper>
        </StyledCardContainer>
    );
}
