import { GET, POST } from './api'
import { ReadObject, SaveObject } from './storage'

export const DoRegister = async params => {
	return await POST(`/users`, params)
}

export const DoLogin = async params => { 
	// const response = { jwt:"fakejwt" }
	const response = await POST(`/auth/local`, params) 
	if (response?.jwt) {
		await SaveObject('authentication', response)
	}
	return response
}

export const DoFirstAccess = async params => {
	console.log("🚀 ~ DoFirstAccess ~ params:", params)
	const response = await POST(`/auth/validate-preregistration`, params) 
	if (response?.jwt) {
		console.log("🚀 ~ DoFirstAccess ~ response:", response)
		await SaveObject('authentication', response)
	}
	return response
}

export const DoFinishFirstAccess = async params => {
	console.log("🚀 ~ DoFinishFirstAccess ~ params:", params);
	try {
			const response = await POST(`/auth/first-access`, params);
			
			if (response?.jwt) {
					console.log("🚀 ~ DoFinishFirstAccess ~ response:", response);
					await SaveObject('authentication', response);
					return response; 
			} else {
					return response;
			}
	} catch (error) {
			console.error("🚀 ~ DoFinishFirstAccess ~ error:", error);
			return false;
	}
};


export const DoLogout = async () => {
	SaveObject('authentication', {})
    try {
        return localStorage.clear()
    } catch (e) {
        return sessionStorage.clear();
    }
} 
  
export const DoForgotPassword = async params => {
	return await POST(`/auth/forgot-password`, params)
}

export const DoResetPassword = async params => {
	return await POST(`/auth/reset-password`, params)
}

export const IsLogged = async () => {
	const authentication = ReadObject('authentication')
	return authentication?.jwt
}

export const ReadMe = async () => {
	return await GET(`/users/me?populate=*`, true)
}  