import { GET, POST, PUT, DELETE } from "./api";

export const Create = async (params) => {
  return await POST(`/vacations`, params, true);
};

export const Read = async (id) => {
  return await GET(
    `/vacations?filters[company]=${id}&_limit=-1&populate=scheduling&pagination[pageSize]=1000`,
    true
  );
};

export const ReadByUploadId = async (uploadId) => {
  return await GET(`/vacations?filters[uploadId]=${uploadId}`, true);
};

export const ReadAllForEmployee = async (id) => {
  return await GET(
    `/vacations?filters[employee]=${id}&_limit=-1&pagination[pageSize]=1000`,
    true
  );
};

export const ReadOne = async (id) => {
  return await GET(`/vacations/${id}?populate=*`, true);
};

export const Update = async (params, id) => {
  return await PUT(`/vacations/${id}`, params, true);
};

export const Delete = async (id) => {
  return await DELETE(`/vacations/${id}`, true);
};

export const GeneratePdf = async (id) => {
  return await POST(`/vacations/generate-pdf`, { id }, true, true);
};
