import styled from 'styled-components'  
import { CardContainer } from '../styled';

export const Content = styled.div.attrs({ 
})`
`;

// Adicionar um styled container para manter consistência
export const StyledCardContainer = styled(CardContainer)`
    height: 100%;
    min-height: 100%;
    max-height: 400px;
    overflow: hidden;
`;