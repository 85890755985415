import React, { useState } from "react";  

import {  
    
    
    CardHomeHeader,
    CardHomeHeaderActions,
    HeaderTabs,
    HeaderTabItem,
    HeaderTabItemBadge,
    HeaderTabsFilter,
    HeaderTabsTitle

} from "./styled";

import DashboardCardWrapper from "../Wrapper";
import Select from "components/Form/Select";
import DashboardActions from "components/Dashboard/Actions";

export default function DashboardCardTabs({ title, tabs, active, setActive, selectable, actions, children }){  

    return ( 
        <>
            <DashboardCardWrapper title={!tabs?.length ? null : title} >
                <CardHomeHeader flat={!tabs?.length}>
                    <HeaderTabsFilter>
                        {
                            !tabs?.length ? <HeaderTabsTitle>{ title }</HeaderTabsTitle> :
                            <HeaderTabs>
                                {
                                    tabs?.map((item, key) => <HeaderTabItem active={active===key} key={key} onClick={() => setActive(key)}>
                                            { item.title }
                                            { !item.badge ? null : <HeaderTabItemBadge>{item.badge}</HeaderTabItemBadge> }
                                        </HeaderTabItem>
                                    )
                                }
                            </HeaderTabs>
                        }
                        <CardHomeHeaderActions>
                            {/* { !selectable ? null : <Select options={selectable?.options||[]} placeholder={selectable?.placeholder} /> } */}
                        </CardHomeHeaderActions>
                    </HeaderTabsFilter>
                    <DashboardActions actions={actions} />
                </CardHomeHeader>
                { children }
            </DashboardCardWrapper>
        </>
    );
}